import { format, parse } from 'date-fns';
import { enGB, fr, de, nl } from 'date-fns/locale';
import { timeFormatDefaultLocale } from 'd3-time-format';

import { USER_LANGUAGE_CODE, LANGUAGE_CODE } from 'utils/constants';
import { timescale_en_GB } from './d3-times-scales';

timeFormatDefaultLocale(timescale_en_GB);

const locales = { en: enGB, fr, de, nl };

// Parse date string from'dd-MM-yyyy' to Date object
export const parseDate = (date: string): Date => {
  return parse(date, 'dd-MM-yyyy', new Date());
};

const getDate = (date: Date | string): Date => {
  if (date instanceof Date) {
    return date;
  } else {
    return new Date(date);
  }
};

export const formatDate = (date: Date | string, formatStr?: string): string => {
  if (!date) return '';

  const newDate: Date = getDate(date);
  const locale: string =
    localStorage.getItem(USER_LANGUAGE_CODE) ||
    localStorage.getItem(LANGUAGE_CODE) ||
    'en';

  return format(newDate, formatStr || 'dd MMM y', {
    locale: locales[locale],
  });
};

export const formatDateTime = (
  date: Date | string,
  formatStr?: string,
): string => {
  if (!date) return '';

  const newDate: Date = getDate(date);
  const locale: string =
    localStorage.getItem(USER_LANGUAGE_CODE) ||
    localStorage.getItem(LANGUAGE_CODE) ||
    'en';

  return format(newDate, formatStr || 'dd MMM y, HH:mm', {
    locale: locales[locale],
  });
};

export const formatTime = (date: Date | string, formatStr?: string): string => {
  if (!date) return '';
  const newDate: Date = getDate(date);

  return format(newDate, formatStr || 'HH:mm');
};

export const isISODateString = (str: string) => {
  return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/gm.test(
    str,
  );
};
