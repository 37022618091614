import { createSelector } from '@reduxjs/toolkit';
import { AlertType, RootState, TaskStatus } from 'types';
import { initialState } from './slice';
import { WorkOrderTableType } from './types';
import { selectEnumLabels } from '../Equipments/selectors';
import { hydrateEquipmentEnumLabels } from './utils';

const selectDomain = (state: RootState) =>
  state.equipmentDetails || initialState;

export const selectEquipmentDetails = createSelector(
  [selectDomain, selectEnumLabels],
  ({ maintainableItem, condition, status }, enumLabels) => ({
    maintainableItem: maintainableItem
      ? hydrateEquipmentEnumLabels(enumLabels)(maintainableItem)
      : undefined,
    condition,
    status,
  }),
);

export const selectMiId = createSelector(
  [selectDomain],
  equipmentDetailsState => equipmentDetailsState.miId,
);

export const selectIsPoolingEquipment = createSelector(
  [selectDomain],
  equipmentDetailsState => equipmentDetailsState.isPoolingEquipment,
);

export const selectIsLoading = () =>
  createSelector(
    [selectDomain],
    equipmentDetailsState => equipmentDetailsState.loading,
  );

export const selectWorkOrdersIsLoading = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].loading,
  );

export const selectWorkOrders = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].data,
  );

export const selectSort = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].sort,
  );

export const selectPageInfo = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].pageInfo,
  );

export const selectWorkOrdersPayload = (workOrderType: WorkOrderTableType) =>
  createSelector([selectDomain], equipmentDetailsState => {
    const { miId, taskTypes } = equipmentDetailsState;
    const { pageInfo, sort } = equipmentDetailsState.workOrders[workOrderType];
    const statusTypes =
      workOrderType === 'planned'
        ? [TaskStatus.DRAFT, TaskStatus.TODO]
        : [TaskStatus.DONE];

    return {
      miId,
      pageIndex: pageInfo?.page,
      pageSize: pageInfo?.pageSize,
      sortField: sort?.columnId || 'createdTime',
      sortOrder: sort?.sortDirection || 'DESC',
      taskTypes,
      statusTypes,
    };
  });

export const selectMaintainableItemDetails = () =>
  createSelector(
    [selectDomain],
    equipmentDetailsState => equipmentDetailsState.loading,
  );

export const selectAlerts = () =>
  createSelector(
    [selectDomain],
    equipmentDetailsState => equipmentDetailsState.alerts,
  );

export const selectAlertsDateRange = () =>
  createSelector([selectAlerts()], alerts => alerts.dateRange);

export const selectAlertsOnlyActive = () =>
  createSelector([selectAlerts()], alerts => alerts.onlyActive);

export const selectAlertsIsInit = () =>
  createSelector([selectAlerts()], alerts => alerts.isInit);

export const selectAlertsIsLoading = () =>
  createSelector([selectAlerts()], alerts => alerts.isLoading);

export const selectAlertsActive = () =>
  createSelector([selectAlerts()], alerts =>
    alerts.onlyActive
      ? alerts.data.filter(alert => alert.isActive)
      : alerts.data,
  );

export const selectAlertsByType = (alertTypes: AlertType[]) =>
  createSelector([selectAlertsActive()], alerts =>
    alerts.filter(alert => alertTypes.includes(alert.type)),
  );
