import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import t from 'locales/translation';
import SensorAlert from 'app/components/SensorAlert';
import { selectEquipmentDetails } from '../selectors';
import { mediaQuery } from 'utils/style-utils';
import { StatusLevel } from 'app/components/StatusLevel';
import CertificateStatusIcon from 'app/containers/EquipmentAttachments/components/CertificateStatusIcon';
import { getCertificateInfo } from 'app/containers/EquipmentAttachments/utils';

function Badges() {
  const { condition, status, maintainableItem } = useSelector(
    selectEquipmentDetails,
  );
  const certificateInfo = maintainableItem
    ? getCertificateInfo(maintainableItem)
    : undefined;

  return (
    <Wrapper>
      {condition?.conditionScore && (
        <Badge>
          <h3>{t('ConditionScore')}:</h3>
          <StatusLevel size="24px" score={condition.conditionScore} />
        </Badge>
      )}
      {status?.severity && (
        <Badge>
          <h3>{t('ThresholdStatus')}:</h3>
          <SensorAlert severity={status.severity} />
        </Badge>
      )}
      {certificateInfo?.certificateStatus && (
        <Badge>
          <h3>{t('Certification')}:</h3>
          <CertificateStatusIcon
            certificateStatus={certificateInfo.certificateStatus}
          />
          <span>{certificateInfo.validUntilLabel}</span>
        </Badge>
      )}
    </Wrapper>
  );
}

export default Badges;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sp12};

  ${mediaQuery.phoneLarge} {
    flex-direction: row;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sp12};
  padding: 0 ${({ theme }) => theme.spacing.sp16};
  height: ${({ theme }) => theme.sizes.sz40};
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 3px;

  > h3 {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    color: ${({ theme }) => theme.colors.default.blue2};
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  > span {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    color: ${({ theme }) => theme.colors.default.gray3};
    text-transform: uppercase;
  }
`;
