import React, { useState, useEffect, Fragment } from 'react';
import { Attribute, BgColor, TextColor } from 'types';
import { getGroupedAttributes, getOrderedAttributeGroupNames } from '../utils';
import { capitalizeFirstLetter } from 'utils/formatters';
import { Div } from 'app/components/Div';
import { StyledDiv } from './AttributesTable';
import styled from 'styled-components/macro';
import { Text } from 'app/components/Typography';
import { SearchForm } from '@eriksdigital/atomic-ui/components';
import { GroupedAttributes } from '../types';
import { translateAttributeLabel } from 'locales/translation';

type Props = {
  attributes: Attribute[];
};

export const AttributesDetails = ({ attributes }: Props) => {
  const [inputString, setInputString] = useState<string>('');
  const attributeGroups = getOrderedAttributeGroupNames(attributes);
  const groupedAttributes = getGroupedAttributes(attributes, attributeGroups);

  useEffect(() => {
    return () => {
      setInputString('');
    };
  }, []);

  const searchResults = (input, attribute): boolean => {
    const value = attribute.displayValue.toLowerCase();
    const translatedLabel = translateAttributeLabel(
      attribute.id,
      attribute.label,
    ).toLowerCase();

    const totalSearchString =
      translatedLabel.split(' ').join('') + value.split(' ').join('');

    return input
      .split(' ')
      .every(searchString =>
        totalSearchString.includes(searchString.toLowerCase()),
      );
  };

  const renderAttributesList: GroupedAttributes[] = groupedAttributes.map(
    (item: GroupedAttributes) => {
      return {
        ...item,
        attributes: item.attributes.filter((attr: Attribute) => {
          if (inputString === '') {
            return true;
          } else {
            return searchResults(inputString, attr);
          }
        }),
      };
    },
  );

  return (
    <Wrapper>
      <SearchBarWrapper>
        <SearchForm
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputString(e.target.value)
          }
          onClick={() => {}}
          placeholder={'Search for'}
          searchButton
          data-testid="search-input"
          id="search"
          value={inputString}
        />
      </SearchBarWrapper>

      {renderAttributesList.map(
        (groupItem: GroupedAttributes, index: number) => {
          return (
            <Fragment key={index}>
              {groupItem.attributes.length > 0 && (
                <Div
                  color={TextColor.primaryNew}
                  bg={BgColor.white}
                  align="center"
                  pl="16px"
                  mt="32px"
                  mb="9px"
                  display="flex"
                >
                  <Text
                    fontSize="ft14"
                    fontWeight="medium"
                    color={TextColor.secondaryNew}
                  >
                    {capitalizeFirstLetter(groupItem.groupName)}
                  </Text>
                </Div>
              )}

              <StyledGrid>
                {groupItem.attributes.map((attributeItem: Attribute) => (
                  <Div
                    key={attributeItem.id}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text fontSize="ft14" color={TextColor.secondaryNew}>
                      {translateAttributeLabel(
                        attributeItem.id,
                        attributeItem.label,
                      )}
                    </Text>
                    <Text fontSize="ft14" color={TextColor.secondaryNew}>
                      {attributeItem.displayValue !== ''
                        ? attributeItem.displayValue
                        : '-'}
                    </Text>
                  </Div>
                ))}
              </StyledGrid>
            </Fragment>
          );
        },
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 460px;
  max-width: 100vw;
`;

const StyledGrid = styled(StyledDiv)`
  & > div {
    padding: 9px 16px;
  }
`;

const SearchBarWrapper = styled.div`
  padding: 0 16px;
  div {
    border-radius: 0;
    border: none;
    background: ${({ theme }) => theme.colors.default.gray1};
    &:focus-within {
      border: none;
    }
  }
  div input {
    font-size: 14px;
  }
  div input::placeholder {
    color: ${({ theme }) => theme.colors.default.gray3};
    font-size: 14px;
  }
  div input:focus {
    border: none;
  }
`;
