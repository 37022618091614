/**
 *
 * QrCode
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import t from 'locales/translation';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { buildEquipmentRoute } from 'utils/route-helpers';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Div } from 'app/components/Div';
import { useCustomerLocation } from '../CustomerLocation';
import { reducer, sliceKey, actions } from './slice';
import { selectQrCode } from './selectors';
import { qrCodeSaga } from './saga';

interface Props {}

export function QrCode(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: qrCodeSaga });

  useCustomerLocation({
    hasCustomerSelector: false,
    hasLocationSelector: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, tag } = useSelector(selectQrCode);

  const { qrId } = useParams() as { qrId: string };

  useEffect(() => {
    dispatch(actions.getTag(qrId));
  }, [dispatch, qrId]);

  useEffect(() => {
    if (!tag) return;

    if (tag.customerId && tag.maintainableItemId) {
      history.push(buildEquipmentRoute(tag.customerId, tag.maintainableItemId));
    }
  }, [history, tag]);

  return (
    <>
      <Helmet>
        <title>QrCode</title>
        <meta name="description" content="Description of QrCode" />
      </Helmet>

      {loading && <LoadingIndicator />}

      {tag && !tag.maintainableItemId && (
        <Div display="flex" mt="50px" pl="16px">
          {t('UnlinkedMaintainableItem')}
        </Div>
      )}
    </>
  );
}
