import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.qrCode || initialState;

export const selectQrCode = createSelector(
  [selectDomain],
  ({ loading, tag, graphs }) => ({
    loading,
    tag,
    graphs,
  }),
);
