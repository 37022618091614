/**
 *
 * TaskDetails
 *
 */

import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  ReactElement,
} from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  CheckCircleIcon,
  RemoveIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import t, { translationString } from 'locales/translation';
import { formatDateTime } from 'locales/date-format-i18n';
import {
  Alert,
  EntityType,
  ValueLabel,
  TaskStatus,
  EventType,
  TaskReq,
  TextColor,
  Task,
  TaskItem,
  Asset,
  IconColor,
} from 'types';
import { useUserList } from 'utils/hooks/useUserList';
import { fetchAlerts } from 'utils/alerts';
import { getColorForStatus } from 'utils/getColor';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Avatar } from 'app/components/Avatar';
import { Divider } from 'app/components/Divider';
import { Modal } from 'app/components/Modal';
import { Text } from 'app/components/Typography';
import { Div } from 'app/components/Div';
import { Signal, SignalMessage } from 'app/components/SignalMessage';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import CustomSelect from 'app/components/Select/CustomSelect';
import { getParentTree } from 'services/hierarchyServices';
import {
  generateAlertInfo,
  generateTaskInfo,
  generateGeneralInfo,
} from 'app/containers/TaskDetails/utils';
import { taskDetailsSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';
import { selectTask, selectWorklogs, selectTaskMetadata } from './selectors';
import { Form } from './Form';

interface Props {
  task: Task;
  item: TaskItem;
}

const getName = (user: {
  id: string;
  name?: string;
  active?: boolean;
}): ReactElement => {
  const { id, name, active } = user;

  return (
    <span className="user" data-stringid="User">
      {name
        ? `${name} ${active ? '' : `(${translationString('Inactive')})`} `
        : `${translationString('User')} ${id} `}
    </span>
  );
};

export function Details({ task, item }: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: taskDetailsSaga });

  const dispatch = useDispatch();

  const { taskLoading, taskError } = useSelector(selectTask);
  const { metadata } = useSelector(selectTaskMetadata);
  const { worklogs, worklogLoading } = useSelector(selectWorklogs);

  const [asset, setAsset] = useState<Asset>();
  const [alert, setAlert] = useState<Alert>();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.DRAFT);
  const [assignee, setAssignee] = useState<ValueLabel>();
  const [modalType, setModalType] = useState<'reopen' | 'close' | undefined>();

  const userList: ValueLabel[] = useUserList();

  const statusList: ValueLabel[] = useMemo(() => {
    if (task?.status) {
      setStatus(task?.status);
    }

    return [
      { label: translationString(TaskStatus.TODO), value: TaskStatus.TODO },
      { label: translationString(TaskStatus.DONE), value: TaskStatus.DONE },
    ];
  }, [task]);

  useEffect(() => {
    if (task.id) {
      dispatch(actions.getWorklogs(task.id));
    }
  }, [dispatch, task.id]);

  // Get task metadata
  useEffect(() => {
    dispatch(actions.getTaskMetadata());
  }, [dispatch]);

  // Get related Asset
  useEffect(() => {
    getParentTree(item.id, 2, [EntityType.ASSET]).then(({ data }) => {
      if (data.parent) setAsset(data.parent);
    });
  }, [item.id]);

  // Get related Alerts
  useEffect(() => {
    fetchAlerts(item.id, item.type, asset?.id).then(data => {
      setAlerts(data);
    });
  }, [asset, item.id, item.type]);

  useEffect(() => {
    if (!task) return;

    const item = userList.find(user => user.value === task.assignee?.id);
    if (item) setAssignee(item);
  }, [task, userList]);

  useEffect(() => {
    if (!task) return;

    const alert = alerts.find(alert => alert.id === task.relatedAlertId);
    if (alert) setAlert(alert);
  }, [alerts, task]);

  // component unmounts
  useEffect(() => {
    return () => {
      dispatch(actions.componentWillUnmount());
    };
  }, [dispatch]);

  return (
    <Fragment>
      {taskLoading && <LoadingIndicator />}
      {taskError && <SignalMessage type={Signal.ALERT} message={taskError} />}

      {task && (
        <>
          <Header>
            <p id="title">{task.title}</p>

            <SelectContainer>
              <CustomSelect
                width="100px"
                selectedItem={{
                  label: translationString(task.status),
                  value: task.status as TaskStatus,
                }}
                options={statusList}
                handleSelection={option => {
                  setStatus(option.value as TaskStatus);

                  if (
                    task.status !== TaskStatus.DONE &&
                    option.value === TaskStatus.DONE
                  ) {
                    setModalType('close');
                  } else if (
                    task.status === TaskStatus.DONE &&
                    option.value === TaskStatus.TODO
                  ) {
                    setModalType('reopen');
                  } else {
                    const updatedTask: TaskReq = {
                      ...task,
                      status: option.value as TaskStatus,
                      assignee: task.assignee?.id,
                      createdBy: task.createdBy?.id,
                    };

                    dispatch(actions.updateTask(updatedTask));
                    setModalType(undefined);
                  }
                }}
              />

              <CustomSelect
                width="200px"
                isSearchable
                selectedItem={assignee || userList[0]}
                options={userList}
                handleSelection={option => {
                  const updatedTask: TaskReq = {
                    ...task,
                    assignee: option.value,
                    createdBy: task.createdBy.id,
                  };

                  dispatch(actions.updateTask(updatedTask));
                  setAssignee(option);
                }}
              />
            </SelectContainer>
          </Header>

          <GridRowStyled>
            <GridColumn desktop={6}>
              <Row>
                <div className="subtitle">{t('Generalinformation')}</div>

                {generateGeneralInfo(item, asset).map(({ label, value }) => (
                  <Fragment key={label}>
                    <Div display="flex" justifyContent="space-between">
                      <div className="label">{t(label)}</div>
                      <div className="text">{value}</div>
                    </Div>
                    <Divider />
                  </Fragment>
                ))}
              </Row>

              {alert && (
                <Row>
                  <div className="subtitle">{t('AlertInformation')}</div>

                  {generateAlertInfo(alert).map(({ label, value }) => (
                    <Fragment key={label}>
                      <Div display="flex" justifyContent="space-between">
                        <div className="label">{t(label)}</div>
                        <div className="text">{value}</div>
                      </Div>

                      <Divider />
                    </Fragment>
                  ))}
                </Row>
              )}

              {task.createdDate && (
                <Div display="flex" mt="8px" data-stringid="CreatedAt">
                  <Text color={TextColor.muted}>
                    {`${translationString('CreatedAt')} ${formatDateTime(
                      task.createdDate,
                    )}`}
                  </Text>
                </Div>
              )}

              {task.closeDate && (
                <Div display="flex" mt="8px" data-stringid="ResolvedAt">
                  <Text color={TextColor.muted}>
                    {`${translationString('ResolvedAt')} ${formatDateTime(
                      task.closeDate,
                    )}`}
                  </Text>
                </Div>
              )}
            </GridColumn>

            <GridColumn desktop={6}>
              <Row>
                <div className="subtitle">
                  {t(`Information.${task.taskType.mainType}`)}
                </div>
                {generateTaskInfo(task, metadata).map(({ label, value }) => (
                  <Fragment key={label}>
                    <Div
                      display="flex"
                      justifyContent="space-between"
                      align="center"
                    >
                      <div className="label">{t(`Attributes.${label}`)}</div>

                      {label !== 'result' ? (
                        <div className="text">{value}</div>
                      ) : (
                        <Div display="flex" gap="8px" align="center">
                          <Icon
                            as={value === 'Pass' ? CheckCircleIcon : RemoveIcon}
                            size="sz16"
                            color={
                              value === 'Pass' ? IconColor.green : IconColor.red
                            }
                          />
                          <Text fontSize="ft14">{value}</Text>
                        </Div>
                      )}
                    </Div>

                    <Divider />
                  </Fragment>
                ))}
              </Row>

              {modalType && (
                <Modal
                  withOutsideClose
                  onClose={() => {
                    setModalType(undefined);
                  }}
                >
                  <Form
                    task={task}
                    type={modalType}
                    status={status}
                    initialAssignee={assignee || userList[0]}
                    users={userList.slice(1)}
                    closeModal={() => setModalType(undefined)}
                  />
                </Modal>
              )}

              {worklogs.length > 0 && (
                <Row>
                  <div className="subtitle">{t('Worklog')}</div>
                  {worklogLoading && <LoadingIndicator small />}

                  {worklogs.map((worklog, index) => {
                    const {
                      id,
                      createdBy,
                      assignee,
                      eventType,
                      status,
                      comment,
                      createdDate,
                    } = worklog;

                    return (
                      <Fragment key={id}>
                        <Div display="flex" align="center">
                          <Avatar
                            name={createdBy.name || translationString('User')}
                          />

                          <div className="text">
                            {getName(createdBy)}

                            {eventType === EventType.TASK_CREATED &&
                              t('CreatedTask')}

                            {eventType === EventType.STATUS_CHANGED && status && (
                              <>
                                {t('ChangedStatusTo')}
                                {` `}
                                <Status status={status}>{status}</Status>
                              </>
                            )}

                            {eventType === EventType.ASSIGNEE_CHANGED && (
                              <>
                                {assignee ? (
                                  <>
                                    {t('ChangedAssigneeTo')}
                                    {` `}
                                    {getName(assignee)}
                                  </>
                                ) : (
                                  t('UnassignedTask')
                                )}
                              </>
                            )}

                            {` ${translationString('at')} `}
                            {formatDateTime(createdDate)}
                          </div>
                        </Div>

                        {comment && <div className="text">{comment}</div>}
                        {index !== worklogs.length - 1 && <Divider />}
                      </Fragment>
                    );
                  })}
                </Row>
              )}
            </GridColumn>
          </GridRowStyled>
        </>
      )}
    </Fragment>
  );
}

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sp16};

  #title {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
    color: ${({ theme }) => theme.colors.heading.primary};
  }

  ${({ theme }) => theme.breakpoints.tabletPortrait`
    display: flex;
    justify-content: space-between;

    #title {
      font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
      margin:0;
    }
  `};
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 8px;
`;

const GridRowStyled = styled(GridRow)`
  flex-direction: column-reverse;

  .subtitle {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
    color: ${({ theme }) => theme.colors.default.blue1};
  }

  .label {
    display: block;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    line-height: 12px;
    color: ${({ theme }) => theme.colors.text.tertiary};
    margin-top: ${({ theme }) => theme.spacing.sp12};
    margin-bottom: ${({ theme }) => theme.spacing.sp12};
  }

  .user {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  .text {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    line-height: 20px;
    margin-top: ${({ theme }) => theme.spacing.sp12};
    margin-bottom: ${({ theme }) => theme.spacing.sp12};

    ::first-letter {
      text-transform: uppercase;
    }
  }

  ${({ theme }) => theme.breakpoints.tabletPortrait`
    flex-direction: row;

    .subtitle {
      font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
      margin-bottom: 12px;
    }

    .label {
      font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    }

    .text {
      font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    }
  `};
`;

const Row = styled.div`
  display: block;
  background: ${({ theme }) => theme.colors.background.white};
  border-radius: 3px;
  padding: 12px;
  margin-right: 20px;
  margin-bottom: 20px;

  hr:last-child {
    display: none;
  }
`;

const Status = styled.span<{ status: TaskStatus }>`
  color: ${({ status, theme }) => getColorForStatus(status, theme)};
`;
