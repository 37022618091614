/**
 *
 * DataTable
 *
 */
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  SortIdleIcon,
  SortUpIcon,
  SortDownIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import React, { AriaAttributes, ReactNode, useMemo } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { mediaQueryMax } from 'utils/style-utils';
import { Checkbox } from 'app/elements/Fields';
import {
  DataTableColumn,
  DataTableColumnType,
  DataTableFilter,
  DataTableRow,
  DataTableSort,
} from './types';
import SelectAll from './components/SelectAll';
import { useSelectContext } from './components/SelectContextProvider';
import EnumFilter from './components/EnumFilter';
import DateFilter from './components/DateFilter';
import HierarchyFilter from './components/HierarchyFilter';

interface Props {
  columns: DataTableColumn[];
  data: DataTableRow[];
  sort?: DataTableSort;
  onSort?: (columnId: string) => void;
  filters?: DataTableFilter;
  onFilter?: (id: string, type: DataTableColumnType, values: string[]) => void;
  isLoading?: boolean;
  pageSize?: number;
  selection?: boolean;
}

export function DataTable({
  columns,
  data,
  sort,
  onSort,
  filters,
  onFilter,
  isLoading = false,
  pageSize = 10,
  selection = false,
}: Props) {
  const { selected, select, toggle } = useSelectContext();
  const visibleColumns = useMemo(
    () => columns.filter(column => column.visible),
    [columns],
  );

  const headerColumns = useMemo(() => {
    const getSortInfo = (
      columnId: string,
    ): { icon: ReactNode; ariaSort: AriaAttributes['aria-sort'] } => {
      if (sort?.columnId !== columnId) {
        return { icon: SortIdleIcon, ariaSort: 'none' };
      }

      return sort?.sortDirection === 'ASC'
        ? { icon: SortDownIcon, ariaSort: 'ascending' }
        : { icon: SortUpIcon, ariaSort: 'descending' };
    };

    return visibleColumns.map(column => {
      const isSortable = column.sortable !== undefined ? column.sortable : true;
      const sortInfo =
        isSortable && !!onSort ? getSortInfo(column.id) : undefined;

      return (
        <th key={`DataTableHeader_${column.id}`} aria-sort={sortInfo?.ariaSort}>
          <ColumnNameButton onClick={() => isSortable && onSort?.(column.id)}>
            {column.label}
            {isSortable && !!onSort && <Icon as={sortInfo?.icon} />}
          </ColumnNameButton>
        </th>
      );
    });
  }, [visibleColumns, onSort, sort]);

  const filterColumns = useMemo(() => {
    if (
      !visibleColumns.some(
        ({ type, enumValues }) => type === 'enum' && enumValues?.length,
      )
    ) {
      return null;
    }

    return visibleColumns.map(column => {
      if (column.type === 'enum' && column.enumValues?.length) {
        return (
          <th key={`DataTableFilter_${column.id}`}>
            <EnumFilter
              column={column}
              selected={filters?.[column.id]?.values || []}
              onSubmit={selected =>
                onFilter?.(column.id, column.type, selected)
              }
            />
          </th>
        );
      }

      if (column.type === 'date' || column.type === 'datetime') {
        return (
          <th key={`DataTableFilter_${column.id}`}>
            <DateFilter
              column={column}
              selected={filters?.[column.id]?.values || []}
              onSubmit={selected =>
                onFilter?.(column.id, column.type, selected)
              }
            />
          </th>
        );
      }
      if (column.type === 'hierarchy') {
        return (
          <th key={`DataTableFilter_${column.id}`}>
            <HierarchyFilter
              name={column.id}
              selected={filters?.[column.id]?.values || []}
              onSubmit={selected =>
                onFilter?.(column.id, column.type, selected)
              }
            />
          </th>
        );
      }

      return <th key={`DataTableFilter_${column.id}`}></th>;
    });
  }, [visibleColumns, onFilter, filters]);

  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            {selection && !!select && (
              <th>
                <SelectAll data={data} />
              </th>
            )}
            {headerColumns}
          </tr>
          {!!onFilter && filterColumns && (
            <tr>
              {selection && <th></th>}
              {filterColumns}
            </tr>
          )}
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={`DataTableRow_${row.id}`}>
              {selection && !!toggle && (
                <td>
                  <Checkbox
                    small
                    name={`DataTableSelect_${row.id}`}
                    checked={selected?.includes(row.id)}
                    onChange={() => toggle(row.id)}
                  />
                </td>
              )}
              {visibleColumns.map(column => (
                <td
                  key={`DataTableCell_${column.id}`}
                  data-title={column.label}
                  data-type={column.type}
                  aria-busy={isLoading}
                >
                  {row.values?.find(value => value.id === column.id)?.value}
                </td>
              ))}
            </tr>
          ))}
          {!data.length &&
            isLoading &&
            [...Array(pageSize)].map((item, index) => (
              <tr key={`DataTableRow_${index}`}>
                {visibleColumns.map(column => (
                  <td
                    key={`DataTableCell_${column.id}`}
                    data-title={column.label}
                    data-type={column.type}
                    aria-busy={isLoading}
                  >
                    {column.label}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

const loadingEffect = keyframes`
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export const ColumnNameButton = styled.button`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  color: inherit;
  font-weight: inherit;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sp4};
  width: 100%;
  padding: 0;
  white-space: nowrap;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  overflow-x: auto;

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.colors.default.gray1};
    appearance: none;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    border-radius: 8px;

    :hover {
      background-color: ${({ theme }) => theme.colors.default.blue1};
      cursor: pointer;
    }
  }

  table {
    width: 100%;

    tr {
      border-top: 1px solid ${({ theme }) => theme.colors.default.gray1};
    }

    th {
      height: 45px;
      padding-top: 6px;
      padding-bottom: 6px;
      color: ${({ theme }) => theme.colors.default.gray3};
      font-weight: 600;

      &[aria-sort='ascending'],
      &[aria-sort='descending'] {
        color: ${({ theme }) => theme.colors.default.blue1};
      }

      &[aria-sort] ${ColumnNameButton} {
        cursor: pointer;
      }
    }

    td {
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      color: ${({ theme }) => theme.colors.default.blue2};
      font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
      vertical-align: baseline;
      white-space: nowrap;
      cursor: default;

      &[aria-busy='true']::before {
        content: '';
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 0;
        right: 0;
        backdrop-filter: blur(3px);
        animation: ${loadingEffect} 1s infinite;
      }
    }

    th + th,
    td + td {
      padding-left: ${({ theme }) => theme.spacing.sp24};
    }
  }

  ${mediaQueryMax.phoneLarge} {
    overflow-x: initial;

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 16px;
          background-color: ${({ theme }) => theme.colors.default.white};
          border-top: none;

          td {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 8px;
            height: unset;
            color: ${({ theme }) => theme.colors.default.darkGray};
            white-space: normal;

            &:not(:last-child) {
              border-bottom: 1px solid
                ${({ theme }) => theme.colors.default.gray1};
            }

            &[data-title]::before {
              content: attr(data-title);
              color: ${({ theme }) => theme.colors.default.black};
            }
          }
        }
      }
    }
  }
`;
