import {
  createAbsoluteUrl,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from './utils/apiUtils';
import * as RESOURCE_PATHS from './resourcePaths';
import {
  EntityType,
  Resource,
  ResourceType,
  TemplateType,
  ExportTemplate,
} from 'types';
import { LogsPayload } from 'app/containers/CustomerManagement/types';
const API_BASE_URL: string = process.env.REACT_APP_API_FILE_MANAGEMENT || '';

export const postResource = async (
  payload: FormData,
  entityId: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCES.replace(/%id/, entityId),
    ),
    payload,
  );
};

export const putResource = async (
  payload: FormData,
  entityId: string,
  resourceId: string,
): Promise<any> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCE.replace(/%id/, entityId).replace(
        /%resourceId/,
        resourceId,
      ),
    ),
    payload,
  );
};
export const deleteResource = async (
  entityId: string,
  resourceId: string,
): Promise<any> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCE.replace(/%id/, entityId).replace(
        /%resourceId/,
        resourceId,
      ),
    ),
  );
};

export const getResourceTypesForAssetType = async (
  assetType: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCE_TYPES.replace(/%id/, assetType),
    ),
  );
};

export const getResourcesForEntity = async (
  entityId: string,
  payload: {
    entityType: EntityType;
    resourceTypes?: ResourceType[];
  },
): Promise<any> => {
  const params = new URLSearchParams({ entityType: payload.entityType });

  if (payload.resourceTypes && payload.resourceTypes.length) {
    payload.resourceTypes.forEach(resourceType =>
      params.append('resourceTypes', resourceType),
    );
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCES.replace(/%id/, entityId),
      params,
    ),
  );
};

export const getResourcesForLocation = async (
  locationId: string,
  resourceTypes: ResourceType[] = [],
): Promise<{ data: Resource[] }> => {
  const params = new URLSearchParams();

  resourceTypes.forEach(resourceType =>
    params.append('resourceTypes', resourceType),
  );

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.LOCATION_RESOURCES.replace(/%id/, locationId),
      params,
    ),
  );
};

export const getResourceTypesFields = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ENTITY_RESOURCE_FIELDS,
    ),
  );
};

export const downloadTemplates = async (
  templateType: TemplateType,
  locationId: string,
  customerId: string,
  miTypeId: string,
): Promise<{ data: any }> => {
  const params = new URLSearchParams();
  params.append('templateType', templateType);
  params.append('locationId', locationId);
  params.append('customerId', customerId);
  params.append('equipmentTypeId', miTypeId);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.HIERARCHY_DOWNLOAD_TEMPLATE,
      params,
    ),
    'vnd.ms-excel',
    'application/vnd.ms-excel',
    'blob',
  );
};

export const uploadTemplates = async (
  payload: FormData,
): Promise<{ data: any }> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.UPLOAD_TEMPLATE,
    ),
    payload,
  );
};

export const uploadLogs = async ({
  locationId,
  customerId,
  pageSize,
  pageIndex,
  sortField,
  sortDirection,
}: LogsPayload) => {
  const params = new URLSearchParams();
  params.append('customerId', customerId);
  params.append('locationId', locationId);
  params.append('pageSize', pageSize.toString());
  params.append('pageIndex', pageIndex.toString());
  params.append('sortField', sortField);
  params.append('sortDirection', sortDirection);
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ALL_ENTITY_RESOURCES,
      params,
    ),
  );
};

export const exportTemplate = async ({
  locationId,
  customerId,
  templeType,
  equipmentTypeId,
  equipmentIdList,
}: ExportTemplate): Promise<{ data: any }> => {
  const params = new URLSearchParams({
    locationId,
    customerId,
    equipmentTypeId,
    equipmentIdList,
    templeType,
  });

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.HIERARCHY_DOWNLOAD_TEMPLATE_WITH_VALUES,
      params,
    ),
    'vnd.ms-excel',
    'application/vnd.ms-excel',
    'blob',
  );
};
