import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { formatDate } from 'locales/date-format-i18n';
import t, { translationString } from 'locales/translation';
import { Alert, AlertType } from 'types';
import { DataTable } from 'app/components/DataTable';
import {
  DataTableColumnType,
  DataTableRow,
} from 'app/components/DataTable/types';
import NothingFound from 'app/components/NothingFound';
import { selectAlertsByType, selectAlertsIsLoading } from '../selectors';
import { StatusLevel } from 'app/components/StatusLevel';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { getWarningLevel } from 'utils/severity';

export const mapAlertToDataTableRow = (alert: Alert): DataTableRow => ({
  id: alert.id,
  values: [
    {
      id: 'date',
      value: formatDate(alert.startTime),
    },
    {
      id: 'diagnostic',
      value: alert.diagnostic ? <LongText>{alert.diagnostic}</LongText> : '--',
    },
    {
      id: 'advice',
      value: alert.advice ? <LongText>{alert.advice}</LongText> : '--',
    },
    {
      id: 'active',
      value: alert.isActive ? t('Active') : '',
    },
    {
      id: 'conditionScore',
      value: (
        <TableDiv>
          <StatusLevel size="20px" score={alert.conditionScore} />
          <Text fontSize="ft14">
            {t(`ConditionScore.${getWarningLevel(alert.conditionScore)}`)}
          </Text>
        </TableDiv>
      ),
    },
  ],
});

type Props = {
  alertTypes: AlertType[];
};

function AlertsDataTable({ alertTypes }: Props) {
  const isLoading = useSelector(selectAlertsIsLoading());
  const alerts = useSelector(selectAlertsByType(alertTypes));
  const columns = [
    {
      id: 'date',
      label: translationString('Date'),
      type: 'date' as DataTableColumnType,
      order: 1,
      visible: true,
    },
    {
      id: 'diagnostic',
      label: translationString('Diagnostic'),
      type: 'string' as DataTableColumnType,
      order: 2,
      visible: true,
    },
    {
      id: 'advice',
      label: translationString('Advice'),
      type: 'string' as DataTableColumnType,
      order: 3,
      visible: true,
    },
    {
      id: 'active',
      label: translationString('ConditionScore.Active'),
      type: 'string' as DataTableColumnType,
      order: 4,
      visible: true,
    },
    {
      id: 'conditionScore',
      label: translationString('ConditionScore'),
      type: 'string' as DataTableColumnType,
      order: 5,
      visible: true,
    },
  ];

  if (!isLoading && alerts.length === 0) {
    return <NothingFound />;
  }

  return (
    <DataTable
      columns={columns}
      data={alerts.map(mapAlertToDataTableRow)}
      isLoading={isLoading}
      pageSize={4}
    />
  );
}

export default AlertsDataTable;

const LongText = styled.p`
  margin: 0;
  max-width: fill-available;
  white-space: normal;
`;

const TableDiv = styled(Div)`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`;
