import React from 'react';

type Props = React.FormHTMLAttributes<HTMLFormElement>;

export default function Form({ children, ...rest }: Props) {
  const handleInvalid = (e: React.FormEvent) => {
    e.preventDefault();

    if (e.isTrusted) {
      e.currentTarget
        .querySelector<HTMLInputElement>('input:invalid, select:invalid')
        ?.focus();
    }
  };

  return (
    <form {...rest} onInvalid={handleInvalid}>
      {children}
    </form>
  );
}
