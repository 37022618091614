import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components/macro';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomCalendarContainer, renderCustomHeader } from './utils';

export interface ChildComponentHandle {
  closeDatePicker: () => void;
}
interface DatepickerProps extends ReactDatePickerProps<string, boolean> {}

export const Datepicker = forwardRef<ChildComponentHandle, DatepickerProps>(
  (props, ref) => {
    const {
      id = 'date-picker',
      dateFormat = 'dd-MM-yyyy',
      selected,
      onChange,
      selectsRange,
      customInput,
      startDate,
      endDate,
      showTimeInput,
      ...otherProps
    } = props;

    const datePickerRef = useRef<DatePicker>(null);

    useImperativeHandle(ref, () => ({
      closeDatePicker() {
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    }));

    return (
      <DatePickerWrapper>
        <DatePicker
          id={id}
          data-testid={id}
          dateFormat={dateFormat}
          selected={selected}
          onChange={onChange}
          customInput={customInput}
          startDate={startDate}
          endDate={endDate}
          selectsRange={selectsRange}
          renderCustomHeader={renderCustomHeader}
          calendarContainer={({ children }) => (
            <CustomCalendarContainer
              selectsRange={selectsRange}
              startDate={startDate}
              endDate={endDate}
              onChange={onChange}
            >
              {children}
            </CustomCalendarContainer>
          )}
          {...otherProps}
          showTimeInput={showTimeInput}
          ref={datePickerRef}
          autoComplete="off"
        />
      </DatePickerWrapper>
    );
  },
);

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: flex;
    font-size: 14px;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      border: none;
      background-color: ${({ theme }) => theme.colors.default.gray1};
      font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
      color: ${({ theme }) => theme.colors.default.gray3};
      padding: ${({ theme }) => `${theme.spacing.sp8}`};
    }

    input:focus {
      outline: none;
    }
  }

  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.background.white};
    border: none;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.text.darkGray};
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--keyboard-selected {
    border-radius: 35px;
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 35px;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: ${({ theme }) => theme.colors.background.primary};
    color: ${({ theme }) => theme.colors.text.white};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--range-end {
    background: ${({ theme }) => theme.colors.text.secondary};
  }
`;
