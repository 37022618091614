import styled, { keyframes } from 'styled-components/macro';

// Define the keyframes for the loading animation
const loadingAnimation = keyframes`
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: calc(250px + 100%) 0;
  }
`;

const StyledInput = styled.input`
  border: none;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.gray3};
  padding: ${({ theme }) => `0 ${theme.spacing.sp8}`};
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.loading {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.default.gray1} 25%,
      ${({ theme }) => theme.colors.default.gray2} 50%,
      ${({ theme }) => theme.colors.default.gray1} 75%
    );
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    color: ${({ theme }) => theme.colors.default.white};
    cursor: default;
  }
`;

export default StyledInput;
