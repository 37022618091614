import styled from 'styled-components/macro';

import { TextColor } from 'types';
import { getTextColor } from 'utils/getColor';

export const Text = styled.span<{
  fontSize?:
    | 'ft10'
    | 'ft12'
    | 'ft14'
    | 'ft16'
    | 'ft20'
    | 'ft24'
    | 'ft32'
    | 'ft40'
    | 'ft72';
  fontWeight?: 'normal' | 'medium';
  text?: 'capitalize' | 'uppercase' | 'lowercase';
  color?: TextColor;
  cursor?: 'pointer';
}>`
  font-weight: ${({ fontWeight, theme }) =>
    theme.fonts.fontWeight[fontWeight || 'normal']};
  font-size: ${({ fontSize, theme }) =>
    theme.fonts.fontSize[fontSize || 'ft12']};
  line-height: ${({ theme }) => theme.fonts.lineHeight.text};
  color: ${({ theme, color }) => getTextColor(theme, color)};
  text-transform: ${({ text }) => text || 'none'};
  cursor: ${({ cursor }) => cursor || 'inherit'};
`;
