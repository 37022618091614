import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Text } from 'app/components/Typography';
import { TextColor } from 'types';
import InputFile from 'app/elements/Fields/InputFile';
import t, { translationString } from 'locales/translation';
import Label from 'app/elements/Fields/Label';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
} from 'app/elements/Buttons';
import Field from 'app/elements/Fields/Field';
import { Form } from 'app/elements/Fields/withValidation';
import useStagingFiles from 'app/containers/EquipmentAttachments/hooks/useStagingFiles';
import StagedFileForm from 'app/containers/EquipmentAttachments/components/StagedFileForm';
import { ResourcePayload } from 'app/containers/EquipmentAttachments/types';
import { buildResourcePayload } from 'app/containers/EquipmentAttachments/utils';
import useEquipmentAttachments from '../hooks/useEquipmentAttachments';

const MAX_FILES_UPLOAD = 10;

type Props = {
  onSubmit: (payload: ResourcePayload[]) => void;
  onClose: () => void;
  intro?: ReactNode;
};

function EquipmentAttachmentsForm({ onSubmit, onClose, intro }: Props) {
  const { fetchResourceTypes } = useEquipmentAttachments();
  const { files, handleDeleteFile, handleChangeInputFile } = useStagingFiles();
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  useEffect(() => {
    setValidationMessage(
      files.length > MAX_FILES_UPLOAD
        ? translationString('MaxFilesUpload', { maxFiles: MAX_FILES_UPLOAD })
        : undefined,
    );
  }, [files, setValidationMessage]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const payload = buildResourcePayload(formData, files);

    onSubmit(payload);
  };

  return (
    <FormStyled onSubmit={handleSubmit}>
      {intro ? (
        <Text color={TextColor.secondaryNew} fontSize="ft14">
          {intro}
        </Text>
      ) : (
        <></>
      )}
      <Field>
        <Label htmlFor="fileUpload">{t('Attachments.Select')}</Label>
        <InputFile
          id="fileUpload"
          multiple
          title={translationString('Attachments.Select')}
          onChange={handleChangeInputFile}
        />
      </Field>
      {!!files.length && (
        <Files>
          {files.map(file => (
            <StagedFileForm
              key={`${file.id}`}
              file={file}
              onDelete={handleDeleteFile}
            />
          ))}
        </Files>
      )}
      {validationMessage && (
        <ValidationMessage>{validationMessage}</ValidationMessage>
      )}
      <ButtonGroup>
        <ButtonSecondary type="button" onClick={onClose}>
          {t('Cancel')}
        </ButtonSecondary>
        <ButtonPrimary
          type="submit"
          disabled={!files.length || !!validationMessage}
        >
          {t('Attachments.Upload')}
        </ButtonPrimary>
      </ButtonGroup>
    </FormStyled>
  );
}

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
  width: 460px;
  max-width: 460px;
`;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ValidationMessage = styled.span`
  color: ${({ theme }) => theme.colors.default.red};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;

export default EquipmentAttachmentsForm;
