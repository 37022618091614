import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from '../slice';
import { equipmentsSaga } from '../saga';
import {
  selectAllEquipmentTypes,
  selectEquipmentType,
  selectIsLoadingMetadata,
  selectMetadata,
} from '../selectors';
import useEquipmentParams from './useEquipmentParams';

function useEquipmentType() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: equipmentsSaga,
  });

  const dispatch = useDispatch();

  const { locationId, miTypeId } = useEquipmentParams();

  const metadata = useSelector(selectMetadata);
  const isLoadingMetadata = useSelector(selectIsLoadingMetadata);
  const equipmentType = useSelector(selectEquipmentType(miTypeId));
  const allEquipmentTypes = useSelector(selectAllEquipmentTypes);

  const fetchMetadata = useCallback(() => {
    dispatch(actions.getMetadata());
  }, [dispatch]);

  const fetchEquipments = useCallback(() => {
    dispatch(
      actions.getEquipments({
        locationId,
        miTypeId,
      }),
    );
  }, [dispatch, locationId, miTypeId]);

  const refetchEquipments = useCallback(() => {
    dispatch(actions.refetchEquipments());
  }, [dispatch]);

  return {
    metadata,
    isLoadingMetadata,
    equipmentType,
    fetchMetadata,
    fetchEquipments,
    allEquipmentTypes,
    refetchEquipments,
  };
}

export default useEquipmentType;
