import React, { useState } from 'react';
import styled from 'styled-components/macro';

import t, { translationString } from 'locales/translation';
import Label from 'app/elements/Fields/Label';
import Field from 'app/elements/Fields/Field';
import { Input, Select } from 'app/elements/Fields/withValidation';
import { Datepicker } from 'app/components/DatePicker';
import { ResourceType } from 'types';
import { CertificateResult, CertificateValidity, StagingFile } from '../types';
import useEquipmentAttachments from '../hooks/useEquipmentAttachments';
import StagedFile from './StagedFile';

type Props = {
  file: StagingFile;
  onDelete: (file: StagingFile) => void;
};

function StagedFileForm({ file, onDelete }: Props) {
  const { resourceTypes } = useEquipmentAttachments();
  const [formValues, setFormValues] = useState({
    resourceType: '',
    reportDate: new Date(),
    isValid: CertificateResult.Pass,
    validUntil: CertificateValidity.Default,
    validUntilOther: CertificateValidity.Default,
  });

  const mappedResourceTypes =
    resourceTypes?.map(resourceType => ({
      value: resourceType,
      label: translationString(`ResourceTypes.${resourceType}`),
    })) || [];

  const handleOnChange = (field: keyof typeof formValues) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const value = { [field]: e.currentTarget.value };
    setFormValues(current => ({ ...current, ...value }));
  };

  const renderCertificateFields = () => {
    const isValidOptions = [
      { value: CertificateResult.Pass, label: translationString('Pass') },
      { value: CertificateResult.Fail, label: translationString('Fail') },
    ];

    const validUntilOptions = [
      {
        value: CertificateValidity.Default,
        label: translationString('12Months'),
      },
      { value: CertificateValidity.Other, label: translationString('Other') },
    ];

    return (
      <>
        <Field>
          <Label>{t('Attachments.StagingTable.Result')}</Label>
          <Select
            name={`isValid#${file.id}`}
            options={isValidOptions}
            value={formValues.isValid}
            onChange={handleOnChange('isValid')}
            required
            placeholder={translationString('SelectPlaceholder')}
          ></Select>
        </Field>
        {formValues.isValid === CertificateResult.Pass && (
          <>
            <Field>
              <Label>{t('Attachments.StagingTable.Expires')}</Label>
              <Select
                name={`validUntil#${file.id}`}
                options={validUntilOptions}
                value={formValues.validUntil}
                onChange={handleOnChange('validUntil')}
                required
                placeholder={translationString('SelectPlaceholder')}
              ></Select>
            </Field>
            {formValues.validUntil === CertificateValidity.Other && (
              <Field>
                <Label>{t('Attachments.StagingTable.SpecifyMonths')}</Label>
                <Input
                  type="number"
                  min="1"
                  max="99"
                  name={`validUntilOther#${file.id}`}
                  value={formValues.validUntilOther}
                  onChange={handleOnChange('validUntilOther')}
                  required
                />
              </Field>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <Field>
        <Label>{t('Attachments.StagingTable.Name')}</Label>
        <StagedFile file={file} onDelete={onDelete} />
      </Field>
      <Field>
        <Label>{t('Attachments.StagingTable.Type')}</Label>
        <Select
          name={`resourceType#${file.id}`}
          options={mappedResourceTypes}
          value={formValues.resourceType}
          onChange={handleOnChange('resourceType')}
          placeholder={translationString('SelectPlaceholder')}
          required
        ></Select>
      </Field>
      <Field>
        <Label>{t('Attachments.StagingTable.ReportDate')}</Label>
        <Datepicker
          id={`reportDate#${file.id}`}
          name={`reportDate#${file.id}`}
          dateFormat="yyyy-MM-dd"
          maxDate={new Date()}
          selected={formValues.reportDate}
          selectsRange={false}
          onChange={date => {
            if (!Array.isArray(date) && date !== null) {
              setFormValues(current => ({
                ...current,
                reportDate: date,
              }));
            }
          }}
        />
      </Field>
      {formValues.resourceType === ResourceType.CERTIFICATE &&
        renderCertificateFields()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
  border: 1px solid ${({ theme }) => theme.colors.default.gray1};
`;

export const FileName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  color: ${({ theme }) => theme.colors.default.blue2};
  background-color: ${({ theme }) => theme.colors.default.gray2};

  button {
    color: ${({ theme }) => theme.colors.default.darkGray};
  }
`;

export default StagedFileForm;
