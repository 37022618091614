import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';
import { sub, add } from 'date-fns';

import { translationString } from 'locales/translation';
import { notifyAxiosError } from 'utils/error-helpers';
import apiCall from 'services/utils/apiCall';
import { getTag as getTagAPI } from 'services/hierarchyServices';
import { getSensorsValues } from 'services/sensorService';
import { actions } from './slice';
import { Alert } from 'types';

export function* getTag(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(getTagAPI, action.payload);

    yield put(actions.getTagSuccess(data));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.TagRetrievingError'),
    );

    yield put(actions.getTagError());
  }
}

export function* getGraphValues(action: PayloadAction<Alert>) {
  const { id, sensorId, propertyId, startTime, endTime } = action.payload;

  try {
    if (!id || !propertyId || !startTime || startTime > endTime) return;

    const start = sub(new Date(startTime), { minutes: 30 });
    const end = add(new Date(endTime), { minutes: 10 });

    const { data: sensorValues } = yield apiCall(
      getSensorsValues,
      sensorId,
      [propertyId],
      start.toISOString(),
      end.toISOString(),
    );

    if (sensorValues[0]) {
      yield put(
        actions.getGraphValuesSuccess({
          [id]: sensorValues[0],
        }),
      );
    }
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.SensorDataRetrievingError'),
    );
  }
}

export function* qrCodeSaga() {
  yield takeLatest(actions.getTag.type, getTag);
  yield takeLatest(actions.getGraphValues.type, getGraphValues);
}
