import React from 'react';
import WorkOrdersPagination from './TasksPagination';
import { DataTable } from 'app/components/DataTable';
import styled from 'styled-components/macro';
import useSortRedux from 'app/components/DataTable/useSortRedux';

export const WorkOrdersTable = ({
  columns,
  data,
  sortSelector,
  setSortAction,
  isLoading,
  pageSelector,
  pageAction,
}) => {
  const { sort: plannedSort, handleSort: handlePlannedSort } = useSortRedux({
    selector: sortSelector,
    action: setSortAction,
  });
  return (
    <>
      <TableContainer>
        <DataTable
          columns={columns}
          data={data ? data : []}
          sort={plannedSort}
          onSort={handlePlannedSort}
          isLoading={isLoading}
        />
      </TableContainer>
      <WorkOrdersPagination selector={pageSelector} action={pageAction} />
    </>
  );
};

export const TableContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.sp16};
  background-color: ${({ theme }) => theme.colors.background.white};
`;
