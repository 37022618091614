import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import t, { translationString } from 'locales/translation';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
} from 'app/elements/Buttons';
import { Form, Select } from 'app/elements/Fields/withValidation';
import { Modal } from 'app/components/Modal';
import { H2 } from 'app/components/Typography';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import useEquipmentType from 'app/containers/Equipments/hooks/useEquipmentType';
import routeHelpers from 'utils/route-helpers';
import { formDataToObject } from 'utils/form-data';
import { actions } from '../slice';
import { selectIsCreatingEquipmentType } from '../selectors';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from 'app/containers/CustomerLocation/selectors';

function CreateEquipmentTypeModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    allEquipmentTypes,
    isLoadingMetadata,
    fetchMetadata,
  } = useEquipmentType();
  const isCreatingEquipmentType = useSelector(selectIsCreatingEquipmentType);
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);

  useEffect(() => {
    if (isCreatingEquipmentType) {
      fetchMetadata();
    }
  }, [fetchMetadata, isCreatingEquipmentType]);

  const handleClose = () => dispatch(actions.setIsCreatingEquipmentType(false));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { miTypeId } = formDataToObject<{ miTypeId: string }>(
      new FormData(e.currentTarget),
    );

    if (!customerId || !locationId || !miTypeId) {
      return;
    }

    history.push(
      routeHelpers.buildEquipmentsRoute(customerId, locationId, miTypeId),
    );

    handleClose();
  };

  if (!isCreatingEquipmentType) {
    return <></>;
  }

  return (
    <Modal onClose={handleClose} withOutsideClose maxWidth={600}>
      <FormStyled onSubmit={handleSubmit}>
        <H2>{t('EquipmentType.AddNew')}</H2>
        {t('EquipmentType.WhatType')}
        {isLoadingMetadata ? (
          <LoadingIndicator />
        ) : (
          <Select
            id="miTypeId"
            name="miTypeId"
            required
            placeholder={translationString('SelectPlaceholder')}
            options={allEquipmentTypes.map(miType => ({
              value: miType.maintainableItemTypeId,
              label: miType.label,
            }))}
          ></Select>
        )}
        <ButtonGroup>
          <ButtonSecondary type="button" onClick={handleClose}>
            {t('Cancel')}
          </ButtonSecondary>
          <ButtonPrimary type="submit" disabled={isLoadingMetadata}>
            {t('EquipmentType.Add')}
          </ButtonPrimary>
        </ButtonGroup>
      </FormStyled>
    </Modal>
  );
}

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > span {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    color: ${({ theme }) => theme.colors.default.blue2};
  }
`;

export default CreateEquipmentTypeModal;
