import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails } from 'app/providers/AuthProvider/selectors';
import { SlidingSideBar } from 'app/components/SlidingSideBar';
import { translationString } from 'locales/translation';
import useEquipmentParams from '../../Equipments/hooks/useEquipmentParams';
import InspectionForm from './InspectionForm';
import { InspectionPayload, InspectionPayloadPost } from '../types';
import { actions } from '../slice';
import { selectAddingStatus } from '../selectors';
import { selectEquipmentDetails } from 'app/containers/EquipmentDetail/selectors';
import InspectionProcess from './InspectionProcess';
import { getInspectionTypes, mapTaskFieldValues } from '../utils';
import InspectionTypesNone from './InspectionTypesNone';

function InspectionSideBar() {
  const { locationId, customerId } = useEquipmentParams();
  const { maintainableItem } = useSelector(selectEquipmentDetails);
  const user = useSelector(selectUserDetails);
  const { addingStatus } = useSelector(selectAddingStatus);

  const dispatch = useDispatch();

  const inspectionTypes = useMemo(
    () => (maintainableItem ? getInspectionTypes([maintainableItem]) : []),
    [maintainableItem],
  );

  const handleSubmit = (payload: InspectionPayload) => {
    if (!maintainableItem) {
      dispatch(actions.addInspectionFailed());
      return;
    }
    const taskFieldValues = mapTaskFieldValues(
      payload.taskFieldValues,
      maintainableItem,
    );

    const payloadPost: InspectionPayloadPost = {
      createdBy: user!.id,
      createdDate: new Date().toISOString(),
      description: '',
      locationId,
      customerId,
      maintainableItemId: maintainableItem.id,
      status: 'DONE',
      title: payload.inspectionTypes || '',
      taskFieldValues,
      taskTypeId: payload.taskTypeId,
    };

    dispatch(actions.addInspection(payloadPost));
  };

  if (!maintainableItem) {
    return <></>;
  }

  return (
    <SlidingSideBar
      title={translationString('Inspections.Add')}
      locked={addingStatus === 'running'}
      onClose={() => dispatch(actions.setIsAdding(false))}
      render={handleClose => {
        if (!inspectionTypes.length) {
          return <InspectionTypesNone onClose={handleClose} />;
        }

        if (addingStatus) {
          return (
            <InspectionProcess status={addingStatus} onClose={handleClose} />
          );
        }

        return (
          <InspectionForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            equipments={[maintainableItem]}
            inspectionTypes={inspectionTypes}
          />
        );
      }}
    />
  );
}

export default InspectionSideBar;
