import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.customerControls || initialState;

export const selectCustomerControls = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState,
);

export const selectAccountHasManyCustomers = createSelector(
  [selectDomain],
  ({ customers }) => {
    return customers.length > 1;
  },
);

export const selectDefaultCustomer = createSelector(
  [selectDomain],
  ({ customers }) => {
    return customers.length > 0 ? customers[0].id : '';
  },
);

export const selectSelectedCustomer = createSelector(
  [selectDomain],
  ({ selectedCustomer }) => selectedCustomer,
);

export const selectSelectedCustomerMeta = createSelector(
  [selectDomain],
  ({ customers, selectedCustomer }) =>
    customers.find(c => c.id === selectedCustomer),
);

export const selectCustomerUpdateLoading = createSelector(
  [selectDomain],
  ({ customerUpdateLoading }) => customerUpdateLoading,
);

export const selectHasCustomerSelector = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.hasCustomerSelector,
);

export const selectLocations = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.locations,
);

export const selectLocationDetails = createSelector(
  [selectDomain, (_, locationId: string) => locationId],
  ({ locations }, locationId) => {
    if (!locations) return;
    return locations.find(location => location.id === locationId);
  },
);

export const selectLocationsLoading = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.locationsLoading,
);

export const selectHasLocationSelector = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.hasLocationSelector,
);

export const selectPath = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.path,
);

export const selectSelectedLocation = createSelector(
  [selectDomain],
  customerControlsState => customerControlsState.selectedLocation || undefined,
);

export const selectHomeLocation = createSelector(
  [selectDomain],
  ({ locations }) => {
    return locations && locations.length ? locations[0].id : '';
  },
);

export const customerHasLocation = createSelector(
  [selectDomain, (_, locationId: string) => locationId],
  ({ locations }, locationId) => {
    if (!locations) return false;
    return !!locations.find(location => location.id === locationId);
  },
);
