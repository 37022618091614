/**
 *
 * Equipment Detail
 *
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import t, { translationString } from 'locales/translation';
import styled from 'styled-components/macro';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';
import { H1 } from 'app/components/Typography';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AlertType, EntityType } from 'types';
import {
  buildEquipmentsRoute,
  buildEquipmentEditRoute,
} from 'utils/route-helpers';
import { useAuth } from 'app/providers/AuthProvider';
import { Div } from 'app/components/Div';
import { PageContent } from 'app/elements/Page';
import { MediaGallery } from 'app/containers/MediaGallery';
import { CAM, FAM, TAM } from 'app/providers/AuthProvider/roles';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { AttributesTable } from './components/AttributesTable';
import { WorkOrdersTab } from './components/WorkOrdersTab';
import { AttributesDetails } from './components/AttributesDetails';
import { EquipmentInspection } from '../EquipmentInspection';
import Badges from './components/Badges';
import { equipmentDetailsSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';
import { selectIsLoading, selectEquipmentDetails } from './selectors';
import { SlidingSideBar } from '../../components/SlidingSideBar';
import Alerts from './components/Alerts';
import Tabs from 'app/components/Tabs';
import SensorAlert from 'app/components/SensorAlert';
import { EquipmentAttachments } from '../EquipmentAttachments/index';
import { MEDIA_RESOURCE_TYPES } from 'utils/constants';
import { StatusLevel } from 'app/components/StatusLevel';
import { useCustomerLocation } from '../CustomerLocation';
import useInspections from '../EquipmentInspection/hooks/useInspections';
import InspectionSideBar from '../EquipmentInspection/components/InspectionSideBar';
import useEquipmentParams from '../Equipments/hooks/useEquipmentParams';
import useEquipmentType from '../Equipments/hooks/useEquipmentType';
import { BackNavigation } from 'app/components/BackNavigation';

export const EquipmentDetail = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentDetailsSaga });

  useCustomerLocation({});

  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId, locationId, miId, miTypeId } = useEquipmentParams();

  const { userHasRole } = useAuth();
  const { condition, status, maintainableItem } = useSelector(
    selectEquipmentDetails,
  );
  const isLoading = useSelector(selectIsLoading());

  const [showAllSpecs, setShowAllSpecs] = useState(false);
  const {
    fetchMetadata: fetchInspectionsMetadata,
    isAddingInspection,
  } = useInspections();
  const { isLoadingMetadata, fetchMetadata } = useEquipmentType();

  useEffect(() => {
    fetchInspectionsMetadata();
  }, [fetchInspectionsMetadata]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    if (!miId) return;
    dispatch(actions.getEquipment(miId));
  }, [dispatch, miId]);

  useEffect(() => {
    dispatch(actions.startPoolingEquipment());

    return () => {
      dispatch(actions.stopPoolingEquipment());
    };
  }, [dispatch]);

  const handleEditEquipment = () => {
    history.push(
      buildEquipmentEditRoute(customerId, locationId, miTypeId, miId),
    );
  };

  if (isLoading || isLoadingMetadata) {
    return <LoadingIndicator />;
  }

  if (!maintainableItem) {
    return null;
  }

  return (
    <>
      <PageContent>
        <Div display="flex" direction="column" gap="24px" mb="24px">
          <>
            <BackNavigation
              label="BackToOverview"
              name={translationString(maintainableItem.type)}
              link={buildEquipmentsRoute(customerId, locationId, miTypeId)}
            />
            <H1>{maintainableItem.name}</H1>
          </>
          <Badges />
        </Div>
        <GridRow>
          <GridColumn desktop={4} mobile={12}>
            <StyledMediaGallery
              hasLightbox
              entityId={maintainableItem?.id || miId}
              entityName={maintainableItem?.name}
              entityType={EntityType.MAINTAINABLE_ITEM}
              canManage={userHasRole([TAM, FAM])}
              resourceTypes={MEDIA_RESOURCE_TYPES}
            />
          </GridColumn>
          <GridColumn desktop={8} mobile={12}>
            {maintainableItem?.attributes && (
              <AttributesTable
                attributes={maintainableItem?.attributes}
                openDetails={setShowAllSpecs}
              />
            )}
          </GridColumn>
        </GridRow>
        <Tabs
          tabs={[
            {
              id: 'condition-alerts',
              label: (
                <Div
                  display="flex"
                  align="center"
                  justifyContent="space-between"
                  gap="8px"
                  width="100%"
                >
                  {t('ConditionAlerts')}
                  {condition?.conditionScore && (
                    <StatusLevel size="18px" score={condition.conditionScore} />
                  )}
                </Div>
              ),
              content: (
                <Alerts alertTypes={[AlertType.CONDITION, AlertType.SURVEY]} />
              ),
            },
            {
              id: 'threshold-alerts',
              label: (
                <Div
                  display="flex"
                  align="center"
                  justifyContent="space-between"
                  gap="8px"
                  width="100%"
                >
                  {t('ThresholdAlerts')}
                  {status?.severity && (
                    <SensorAlert severity={status.severity} />
                  )}
                </Div>
              ),
              content: <Alerts alertTypes={[AlertType.STATUS]} />,
            },
            {
              id: 'work-orders',
              label: t('WorkOrders'),
              content: <WorkOrdersTab miId={miId} />,
            },
            {
              id: 'inspections',
              label: t('Inspections'),
              content: <EquipmentInspection />,
            },
            {
              id: 'documents',
              label: t('DocumentsAndCertificates'),
              content: <EquipmentAttachments />,
            },
          ]}
        />
      </PageContent>
      {isAddingInspection && <InspectionSideBar />}
      {showAllSpecs && (
        <SlidingSideBar
          onClose={() => setShowAllSpecs(false)}
          onEdit={
            userHasRole([CAM, FAM, TAM]) ? handleEditEquipment : undefined
          }
          render={() =>
            maintainableItem &&
            maintainableItem.attributes && (
              <AttributesDetails
                attributes={maintainableItem.attributes}
              ></AttributesDetails>
            )
          }
          title={t('Specifications')}
        />
      )}
    </>
  );
};

const StyledMediaGallery = styled(MediaGallery)`
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
  position: relative;

  & div:nth-child(2) {
    height: 38px;
    background: ${({ theme }) => theme.colors.background.white};
    justify-content: center;
  }
`;
