/**
 *
 * TaskDetails
 *
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@eriksdigital/atomic-ui/components';

import t, { translationString } from 'locales/translation';
import { TaskStatus, TaskReq, Task, ValueLabel } from 'types';
import { H3 } from 'app/components/Typography';
import CustomSelect from 'app/components/Select/CustomSelect';
import { Label } from 'app/elements/Fields';
import { FieldWrapper } from 'app/components/EntityForms/FieldWrapper';
import { FormControls } from 'app/components/EntityForms/FormControls';
import { FinalDatePicker, FinalTextArea } from 'app/components/FinalField';
import { actions } from './slice';

interface Props {
  type: 'reopen' | 'close';
  task: Task;
  status: TaskStatus;
  initialAssignee: ValueLabel;
  users: ValueLabel[];
  closeModal: Function;
}

export const Form = ({
  type,
  task,
  status,
  closeModal,
  initialAssignee,
  users,
}: Props) => {
  const dispatch = useDispatch();

  const [assignee, setAssignee] = useState<ValueLabel>(initialAssignee);
  const [closeDate, setCloseDate] = useState<Date | undefined>(new Date());
  const [comment, setComment] = useState('');

  return (
    <form>
      <H3>{type === 'reopen' ? t('ReopenTask') : t('CompleteTask')}</H3>

      {type === 'close' ? (
        <FieldWrapper required>
          <FinalDatePicker
            data-testid="closeDate"
            label={t('Attributes.closeDate')}
            showTimeSelect
            minDate={new Date(task.createdDate)}
            input={{
              id: 'closeDate',
              name: 'closeDate',
              value: closeDate,
              onChange: (date: Date) => setCloseDate(date),
            }}
          />
        </FieldWrapper>
      ) : (
        <FieldWrapper>
          <Label>{t('Assignee')}</Label>

          <CustomSelect
            isSearchable
            selectedItem={assignee}
            options={users}
            handleSelection={option => {
              setAssignee(option);
            }}
          />
        </FieldWrapper>
      )}

      <FieldWrapper>
        <Label required>{t('Comment')}</Label>

        <FinalTextArea
          id="comment"
          data-testid="comment"
          placeholder={translationString('Comment')}
          disabledResize={true}
          rows="6"
          maxLength="1024"
          input={{
            id: 'comment',
            name: 'comment',
            value: comment,
            onChange: e => setComment(e.target.value),
          }}
        />
      </FieldWrapper>

      <FormControls>
        <Button
          id="cancel_button"
          type="button"
          variant="secondary"
          stretch="auto"
          onClick={e => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t('Cancel')}
        </Button>

        <Button
          id="save_button"
          stretch="auto"
          disabled={!comment}
          onClick={e => {
            e.preventDefault();

            const updatedTask: TaskReq = {
              ...task,
              status,
              comment,
              closeDate: closeDate?.toISOString(),
              assignee: assignee?.value,
              createdBy: task.createdBy?.id,
            };

            dispatch(actions.updateTask(updatedTask));
            closeModal();
          }}
        >
          {t('SubmitTask')}
        </Button>
      </FormControls>
    </form>
  );
};
