import React from 'react';
import { buildEquipmentDetailRoute } from 'utils/route-helpers';
import { Link } from 'app/elements/Links';
import useEquipmentParams from '../hooks/useEquipmentParams';

type Props = {
  label: string;
  miId: string;
};

function EquipmentLink({ label, miId }: Props) {
  const { customerId, locationId, miTypeId } = useEquipmentParams();

  return (
    <Link
      to={buildEquipmentDetailRoute(customerId, locationId, miTypeId, miId)}
    >
      {label}
    </Link>
  );
}

export default EquipmentLink;
