import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';

import t from 'locales/translation';
import { DataTableColumn } from 'app/components/DataTable/types';
import { DataTable } from 'app/components/DataTable';
import useSortRedux from 'app/components/DataTable/useSortRedux';
import { Text } from 'app/components/Typography';
import useEquipmentParams from 'app/containers/Equipments/hooks/useEquipmentParams';
import InspectionsPagination from '../EquipmentDetail/components/TasksPagination';
import {
  TableHeader,
  TasksWrapper,
} from '../EquipmentDetail/components/WorkOrdersTab';
import { TableContainer } from '../EquipmentDetail/components/WorkOrdersTable';
import { actions } from './slice';
import {
  selectInspections,
  selectInspectionsHydrated,
  selectInspectionsPageInfo,
  selectInspectionsSort,
  selectMetadata,
} from './selectors';
import useInspections from './hooks/useInspections';
import InspectionNewButton from './components/InspectionNewButton';
import { inspectionsTableColumns, mapInspectionsToDataTableRow } from './utils';

export const EquipmentInspection = () => {
  const { customerId, locationId } = useEquipmentParams();
  const { pathname } = useLocation();

  const { fetchInspections } = useInspections();
  const inspectionsHydrated = useSelector(selectInspectionsHydrated);
  const { isLoading: isLoadingMetadata } = useSelector(selectMetadata);
  const { loading: isLoadingInspections } = useSelector(selectInspections);
  const isLoading = isLoadingInspections || isLoadingMetadata;

  const inspectionsTableData = useMemo(
    () =>
      inspectionsHydrated
        ? inspectionsHydrated?.map(item =>
            mapInspectionsToDataTableRow(
              item,
              customerId,
              locationId,
              pathname,
            ),
          )
        : [],
    [customerId, inspectionsHydrated, locationId, pathname],
  );

  const { sort, handleSort } = useSortRedux({
    selector: selectInspectionsSort,
    action: actions.setInspectionsSort,
  });
  const columns: Array<DataTableColumn> = inspectionsTableColumns();

  useEffect(() => {
    fetchInspections();
  }, [fetchInspections]);

  return (
    <TasksWrapper>
      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <Text fontSize="ft14" fontWeight="medium">
              {t('Inspections.previous')}
            </Text>
            <InspectionNewButton />
          </TableHeader>
          <TableContainer>
            <DataTable
              columns={columns}
              data={inspectionsTableData}
              sort={sort}
              onSort={handleSort}
              isLoading={isLoading}
              pageSize={3}
            />
          </TableContainer>
          <InspectionsPagination
            selector={selectInspectionsPageInfo}
            action={actions.setInspectionsPageInfo}
          />
        </GridColumn>
      </GridRow>
    </TasksWrapper>
  );
};
