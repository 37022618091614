/**
 *
 * Settings
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';
import { cloneDeep } from 'lodash';

import t from 'locales/translation';
import { TextColor, BgColor, User } from 'types';
import { generateIntl } from 'locales/intl';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { LANGUAGE_CODE } from 'utils/constants';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useAuth, useUserDetails } from 'app/providers/AuthProvider';
import { actions } from 'app/providers/AuthProvider/slice';
import { Text } from 'app/components/Typography';
import { Div } from 'app/components/Div';
import { selectEquipment } from 'app/containers/Equipment/selectors';

import en from 'locales/en.json';
import de from 'locales/de.json';
import fr from 'locales/fr.json';
import nl from 'locales/nl.json';
import flag_en from 'assets/icons/flag_en.svg';
import flag_de from 'assets/icons/flag_de.svg';
import flag_fr from 'assets/icons/flag_fr.svg';
import flag_nl from 'assets/icons/flag_nl.svg';

const locale = { de, en, nl, fr };
const flags = { flag_de, flag_en, flag_nl, flag_fr };

export function Settings() {
  const dispatch = useDispatch();
  const user = useUserDetails();
  const storage = useLocalStorage(LANGUAGE_CODE, 'en');
  const { isAuthenticated } = useAuth();
  const { languages } = useSelector(selectEquipment);

  const { width } = useWindowSize();
  const isMobile = width <= breakpointObj.tabletPortrait;

  const [activeLanguage, setActiveLanguage] = useState<string>(
    user?.language || storage[0],
  );

  const handleLanguageChange = (lang: string) => {
    if (lang === activeLanguage) return;

    if (isAuthenticated() && user) {
      const updatedUser: User = cloneDeep(user);
      updatedUser.language = lang;

      dispatch(actions.updateUser(updatedUser));
    } else {
      storage[1](lang);
      generateIntl({
        locale: lang,
        messages: locale[lang],
      });
    }

    setActiveLanguage(lang);
  };

  return (
    <Container
      isMobile={isMobile}
      isAuthedUser={isAuthenticated()}
      padding="18px 12px"
      bg={BgColor.lightGray}
      color={TextColor.secondary}
    >
      <Text fontSize="ft14" fontWeight="medium">
        {t('WhatLanguageDoYouPrefer')}
      </Text>

      <Languages display="flex" mt="24px">
        {languages.map(lang => (
          <Language
            key={lang.id}
            bg={BgColor.white}
            padding="8px"
            mr="16px"
            mb="8px"
            borderRad="3px"
            isActive={lang.code === activeLanguage}
            onClick={() => handleLanguageChange(lang.code)}
          >
            <Image src={flags[`flag_${lang.code}`]} alt={`${lang}_flag`} />
            <Text>{t(`Translation.${lang.code}`)}</Text>
          </Language>
        ))}
      </Languages>
    </Container>
  );
}

const Container = styled(Div)<{ isMobile: boolean; isAuthedUser: boolean }>`
  position: absolute;
  top: ${({ isAuthedUser, theme }) =>
    isAuthedUser ? theme.sizes.sz72 : '52px'};
  width: ${({ isMobile }) => (isMobile ? '100%' : '400px')};
  left: ${({ isMobile }) => (isMobile ? '0' : '12px')};
  min-height: 400px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  z-index: ${({ theme }) => theme.stacking.popUp};
`;

const Languages = styled(Div)`
  flex-wrap: wrap;
`;

const Language = styled(Div)<{ isActive: boolean }>`
  background: ${({ isActive }) => isActive && '#ACE1F4'};
  cursor: pointer;
`;

const Image = styled.img`
  margin-right: 8px;
`;
