import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import t, { translationString } from 'locales/translation';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
} from 'app/elements/Buttons';
import { Form } from 'app/elements/Fields/withValidation';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Field, Label } from 'app/elements/Fields';
import Select from 'app/components/Select';
import useSelection from 'app/components/DataTable/useSelection';
import { selectUserDetails } from 'app/providers/AuthProvider/selectors';
import { MaintainableItem } from 'types';
import { InspectionPayload, InspectionType } from '../types';
import useConditional from '../hooks/useConditional';
import InspectionField from './InspectionField';
import {
  buildTaskFieldValues,
  findEnumByKey,
  findFieldByName,
  getInspectionResult,
  getInspectionTypesLabel,
} from '../utils';
import {
  selectInspectionTaskTypeId,
  selectMetadata,
  selectSeparatedInspectionFields,
} from '../selectors';

type Props = {
  onSubmit: (payload: InspectionPayload) => void;
  onClose: () => void;
  equipments: MaintainableItem[];
  inspectionTypes: InspectionType[];
};

function InspectionForm({
  onSubmit,
  onClose,
  equipments,
  inspectionTypes,
}: Props) {
  const { isLoading } = useSelector(selectMetadata);
  const { inspectionTypeFields, commonFields, equipmentFields } = useSelector(
    selectSeparatedInspectionFields(equipments),
  );
  const taskTypeId = useSelector(selectInspectionTaskTypeId);
  const user = useSelector(selectUserDetails);

  const initialValues = useMemo(
    () => ({
      inspectorName: user?.name,
    }),
    [user],
  );

  const { filteredFields, handleChange } = useConditional({
    fields: commonFields,
  });
  const { selected, toggle } = useSelection();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const taskFieldValues = buildTaskFieldValues(
      commonFields,
      inspectionTypeFields,
      equipmentFields,
      selected as InspectionType[],
      formData,
    );
    const inspectionTypes = getInspectionTypesLabel(
      selected as InspectionType[],
    );
    const inspectionResult = getInspectionResult(commonFields, formData);

    onSubmit({
      taskFieldValues,
      taskTypeId,
      inspectionTypes,
      inspectionResult,
    });
  };

  const handleSelectInpectionType = (fieldName: string) => {
    const field = findFieldByName(inspectionTypeFields, fieldName);
    const enumKey = selected.includes(fieldName) ? 'no' : 'yes';
    const enumItem = field ? findEnumByKey(field, enumKey) : null;

    toggle(fieldName);

    if (field && enumItem) {
      handleChange(enumItem.id, field);
    }
  };

  return (
    <FormStyled onSubmit={handleSubmit}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && !!inspectionTypeFields.length && (
        <>
          <Field>
            <Label required>{t('Inspections.Type')}</Label>
            <Select
              name="inspectionType"
              options={inspectionTypeFields.map(({ fieldName }) => ({
                id: fieldName,
                content: translationString(`Inspections.Type.${fieldName}`),
                disabled: !inspectionTypes.includes(
                  fieldName as InspectionType,
                ),
              }))}
              selected={selected}
              onSelect={handleSelectInpectionType}
            />
          </Field>
        </>
      )}
      {filteredFields?.map(field => (
        <InspectionField
          key={`InspectionField_${field.id}`}
          field={field}
          onChange={handleChange}
          defaultValue={initialValues[field.fieldName]}
        />
      ))}
      <ButtonGroup>
        <ButtonSecondary type="button" onClick={onClose}>
          {t('Cancel')}
        </ButtonSecondary>
        <ButtonPrimary type="submit" disabled={!selected.length}>
          {t('Inspections.Add')}
        </ButtonPrimary>
      </ButtonGroup>
    </FormStyled>
  );
}

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
  width: 460px;
`;

export default InspectionForm;
