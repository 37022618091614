import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { AddAltIcon } from '@eriksdigital/atomic-ui/components/Icons';
import {
  selectCertificatesSort,
  selectDocumentsAndCertificates,
  selectIsLoadingDocuments,
  selectUploadStatus,
} from './selectors';
import { actions } from './slice';
import {
  certificatesTableColumns,
  mapCertificatesToDataTableRow,
} from './utils';
import { DataTableColumn } from 'app/components/DataTable/types';
import { DataTable } from 'app/components/DataTable';
import useSortRedux from 'app/components/DataTable/useSortRedux';
import t from 'locales/translation';
import { ButtonSecondary } from 'app/elements/Buttons';
import EquipmentAttachmentsSidebar from 'app/containers/EquipmentAttachments/components/EquipmentAttachmentsSidebar';
import { TableContainer } from '../EquipmentDetail/components/WorkOrdersTable';
import useEquipmentAttachments from './hooks/useEquipmentAttachments';

export const EquipmentAttachments = () => {
  const { resourceTypes, fetchResourceTypes } = useEquipmentAttachments();
  const dispatch = useDispatch();
  const documents = useSelector(selectDocumentsAndCertificates);
  const isLoading = useSelector(selectIsLoadingDocuments);
  const { isUploading } = useSelector(selectUploadStatus);
  const documentRows =
    Array.isArray(documents) && documents?.map(mapCertificatesToDataTableRow); //when there are no documents available the api returns an empty string

  const columns: Array<DataTableColumn> = certificatesTableColumns();

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  useEffect(() => {
    if (!!resourceTypes?.length) {
      dispatch(actions.getDocumentsAndCertificates());
    }
  }, [dispatch, resourceTypes]);

  const { sort, handleSort } = useSortRedux({
    selector: selectCertificatesSort,
    action: actions.setCertificatesSort,
  });

  return (
    <Wrapper>
      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <ButtonSecondary
              onClick={() => dispatch(actions.setIsUploading(true))}
            >
              <Icon as={AddAltIcon} size="sz20" />
              {t('Attachments.Upload')}
            </ButtonSecondary>
          </TableHeader>
          <TableContainer>
            <DataTable
              columns={columns}
              data={documentRows || []}
              isLoading={isLoading}
              sort={sort}
              onSort={handleSort}
            />
          </TableContainer>
        </GridColumn>
      </GridRow>
      {isUploading && <EquipmentAttachmentsSidebar />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp16};
`;

const TableHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sp16};
  display: flex;
  justify-content: flex-end;
`;
