import { ReactNode } from 'react';

import {
  Asset,
  Alert,
  AlertType,
  Task,
  TaskItem,
  TaskTypeMetadata,
  TaskFieldValue,
  TaskTypeField,
  TaskTypes,
  ValueLabel,
  EntityType,
} from 'types';
import { getConditionOrStatus } from 'utils/getConditionOrStatus';
import {
  formatDateTime,
  formatDate,
  parseDate,
} from 'locales/date-format-i18n';
import { getValue } from 'utils/getDiagnosticAdviceValue';

export const getTaskItem = (task: Task) => {
  return task.maintainableItemId
    ? {
        id: task.maintainableItemId,
        name: task.maintainableItemName || '',
        type: EntityType.MAINTAINABLE_ITEM,
      }
    : {
        id: task.subUnitId || '',
        name: task.subUnitName || '',
        type: EntityType.SUB_UNIT,
      };
};

// Generate general information about the asset
export const generateGeneralInfo = (
  taskItem: TaskItem,
  asset?: Asset,
): ValueLabel[] => {
  return [
    { label: 'TableColumnHeaders.AssetName', value: asset?.name || '' },
    { label: 'PositionNumber', value: asset?.positionNumber || '' },
    { label: taskItem.type, value: taskItem.name },
  ];
};

// Generate alert information
export const generateAlertInfo = (
  alert: Alert,
): { value: ReactNode; label: string }[] => {
  return [
    { label: 'Sensor', value: alert.name || '' },
    {
      label: alert.type === AlertType.STATUS ? 'Status' : 'ConditionScore',
      value: getConditionOrStatus(alert),
    },
    { label: 'TriggeredOn', value: formatDateTime(alert.startTime) },
    {
      label: 'Diagnostic',
      value: getValue('Diagnostic', alert.diagnostic, alert.diagnosticCode),
    },
    {
      label: 'Advice',
      value: getValue('Advice', alert.advice, alert.adviceCode),
    },
  ];
};

// Generate task information based on task type and metadata
export const generateTaskInfo = (
  task: Task,
  metadata: TaskTypeMetadata[],
): ValueLabel[] => {
  if (task.taskType.mainType === TaskTypes.WORK_ORDER) {
    return [
      {
        label: 'creationDate',
        value: task.createdDate ? formatDateTime(task.createdDate) : '',
      },
      {
        label: 'closeDate',
        value: task.closeDate ? formatDateTime(task.closeDate) : '',
      },
      { label: 'description', value: task.description },
    ];
  }

  const taskMetadata =
    metadata.find(m => m.mainType === task.taskType.mainType)?.taskTypeFields ||
    [];

  return hydrateTaskFieldValues(task.taskFieldValues, taskMetadata);
};

// Hydrate task field values with corresponding metadata
export function hydrateTaskFieldValues(
  taskFieldValues: TaskFieldValue[],
  taskTypeFields: TaskTypeField[],
): ValueLabel[] {
  const updatedTaskTypeFields: ValueLabel[] = taskTypeFields.reduce(
    (accumulator, field) => {
      const matchingValue = taskFieldValues.find(
        value => value.taskTypeFieldId === field.id,
      );
      if (matchingValue) {
        let value = `${matchingValue.value} ${field.unitOfMeasurement}`;

        if (field.fieldType === 'date' && matchingValue.value) {
          value = formatDate(parseDate(matchingValue.value));
        } else if (field.fieldType === 'enum') {
          value =
            field.taskTypeFieldEnums?.find(m => m.id === matchingValue.value)
              ?.name || '';
        }

        accumulator.push({
          label: matchingValue.fieldName,
          value,
        });
      }
      return accumulator;
    },
    [] as ValueLabel[],
  );

  return updatedTaskTypeFields;
}
