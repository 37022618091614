import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { Tag, Alert, SensorProperty } from 'types';

// The initial state of the QrCode container
export const initialState: ContainerState = {
  loading: false,
};

const qrCodeSlice = createSlice({
  name: 'qrCode',
  initialState,
  reducers: {
    getTag(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: true,
      };
    },
    getTagSuccess(state, action: PayloadAction<Tag>) {
      return {
        ...state,
        loading: initialState.loading,
        tag: action.payload,
      };
    },
    getTagError(state, action: PayloadAction) {
      return {
        ...state,
        loading: initialState.loading,
      };
    },

    getGraphValues(state, action: PayloadAction<Alert>) {
      return {
        ...state,
      };
    },
    getGraphValuesSuccess(
      state,
      action: PayloadAction<Record<string, SensorProperty>>,
    ) {
      return {
        ...state,
        graphs: { ...state.graphs, ...action.payload },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = qrCodeSlice;
