import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';
import t from 'locales/translation';
import { TaskTypes } from 'types';
import { Text } from 'app/components/Typography';
import useEquipmentParams from 'app/containers/Equipments/hooks/useEquipmentParams';
import { DataTableColumn } from 'app/components/DataTable/types';
import {
  selectPageInfo,
  selectSort,
  selectWorkOrders,
  selectWorkOrdersIsLoading,
} from '../selectors';
import { actions } from '../slice';
import { mapWorkOrdersToDataTableRow, workOrdersTableColumns } from '../utils';
import { WorkOrdersTable } from './WorkOrdersTable';
import TaskEditorModal from './TaskEditorModal';
import { selectEquipmentDetails } from './../selectors';
import { useLocation } from 'react-router-dom';

export const WorkOrdersTab = ({ miId }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { customerId, locationId } = useEquipmentParams();

  const loadingPlannedWorkOrders = useSelector(
    selectWorkOrdersIsLoading('planned'),
  );
  const loadingPreviousWorkOrders = useSelector(
    selectWorkOrdersIsLoading('previous'),
  );
  const { maintainableItem } = useSelector(selectEquipmentDetails);
  const plannedWorkOrders = useSelector(selectWorkOrders('planned'));
  const previousWorkOrders = useSelector(selectWorkOrders('previous'));
  const plannedData = plannedWorkOrders?.map(item =>
    mapWorkOrdersToDataTableRow(item, customerId, locationId, pathname),
  );
  const previousData = previousWorkOrders?.map(item =>
    mapWorkOrdersToDataTableRow(item, customerId, locationId, pathname),
  );

  const columns: Array<DataTableColumn> = workOrdersTableColumns();

  useEffect(() => {
    dispatch(
      actions.getPlannedWorkOrders({
        miId,
        taskTypes: TaskTypes.WORK_ORDER,
      }),
    );
    dispatch(
      actions.getPreviousWorkOrders({
        miId,
        taskTypes: TaskTypes.WORK_ORDER,
      }),
    );
  }, [dispatch, miId]);

  return (
    <TasksWrapper>
      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <Text fontSize="ft14" fontWeight="medium">
              {t('WorkOrders.planned')}
            </Text>
            <TaskEditorModal miId={miId} name={maintainableItem?.name || ''} />
          </TableHeader>
          <WorkOrdersTable
            columns={columns}
            data={plannedData}
            sortSelector={selectSort('planned')}
            setSortAction={actions.setPlannedSort}
            isLoading={loadingPlannedWorkOrders}
            pageSelector={selectPageInfo('planned')}
            pageAction={actions.setPlannedPageInfo}
          />
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <Text fontSize="ft14" fontWeight="medium">
              {t('WorkOrders.previous')}
            </Text>
          </TableHeader>
          <WorkOrdersTable
            columns={columns}
            data={previousData}
            sortSelector={selectSort('previous')}
            setSortAction={actions.setPreviousSort}
            isLoading={loadingPreviousWorkOrders}
            pageSelector={selectPageInfo('previous')}
            pageAction={actions.setPreviousPageInfo}
          />
        </GridColumn>
      </GridRow>
    </TasksWrapper>
  );
};

export const TasksWrapper = styled.div`
  margin-top: 25px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sp24};
  margin-bottom: ${({ theme }) => theme.spacing.sp16};
`;
