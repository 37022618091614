import React from 'react';

import { translationString } from 'locales/translation';
import { formatDate } from 'locales/date-format-i18n';
import { Attribute, MaintainableItem, Task, LinkPath } from 'types';
import { capitalizeFirstLetter } from 'utils/formatters';
import { buildTaskDetailsRoute } from 'utils/route-helpers';
import { Link } from 'app/elements/Links';
import {
  DataTableColumn,
  DataTableRow,
  DataTableValue,
} from 'app/components/DataTable/types';
import { GroupedAttributes } from './types';

const workOrdersTableColumnTitles: Array<string> = [
  'TaskType',
  'Title',
  'CreatedTime',
  'Status',
  'Assignee',
];

export const workOrdersTableColumns = (): Array<DataTableColumn> =>
  workOrdersTableColumnTitles.map((columnData: string, index: number) => {
    return {
      order: index,
      visible: true,
      type: 'string',
      id: columnData,
      label: translationString(
        columnData === 'TaskType' ? 'Inspections.taskType' : columnData,
      ),
      enumValues: [],
    };
  });

export const mapWorkOrdersToDataTableRow = (
  taskItem: Task,
  customerId: string,
  locationId: string,
  pathname: string,
): DataTableRow => ({
  id: taskItem.id,
  values: workOrderTableAttributes(taskItem, customerId, locationId, pathname),
});

export const workOrderTableAttributes = (
  taskItem: Task,
  customerId: string,
  locationId: string,
  pathname: string,
): DataTableValue[] => {
  const linkPath: LinkPath = {
    pathname: buildTaskDetailsRoute(customerId, locationId, taskItem.id),
    state: {
      prevPath: pathname,
      prevName: taskItem.maintainableItemName,
    },
  };

  return [
    {
      id: 'TaskType',
      value: capitalizeFirstLetter(
        taskItem.taskType.mainType.replace(/_/g, ' '),
      ),
    },
    {
      id: 'Title',
      value: <Link to={linkPath}>{taskItem.title}</Link>,
    },
    {
      id: 'CreatedTime',
      value: formatDate(taskItem.createdDate),
    },
    {
      id: 'Status',
      value: capitalizeFirstLetter(taskItem.status),
    },
    {
      id: 'Assignee',
      value: <div>{taskItem.assignee?.name}</div>,
    },
  ];
};

export const getOrderedAttributeGroupNames = (attributes): string[] => {
  const groupMap = new Map();

  for (const attr of attributes) {
    if (
      attr.attributeGroupName &&
      typeof attr.attributeGroupSequence === 'number'
    ) {
      const existingSequence = groupMap.get(attr.attributeGroupName);
      if (
        existingSequence === undefined ||
        attr.attributeGroupSequence < existingSequence
      ) {
        groupMap.set(attr.attributeGroupName, attr.attributeGroupSequence);
      }
    }
  }

  const sortedGroups = Array.from(groupMap.entries()).sort((a, b) => {
    if (a[1] !== b[1]) {
      return a[1] - b[1];
    } else {
      return a[0].localeCompare(b[0]);
    }
  });
  return sortedGroups.map(entry => entry[0]);
};

export const getGroupedAttributes = (
  attributes: Attribute[],
  groupsName: string[],
): GroupedAttributes[] => {
  const grouped = groupsName.map(groupName => {
    return {
      groupName,
      attributes: attributes
        .filter(attr => attr.attributeGroupName === groupName && attr.visible)
        .sort((a, b) => a.order - b.order),
    };
  });
  return grouped;
};

export const hydrateEquipmentEnumLabels = (enumLabels: {
  [key: string]: string;
}) => (equipment: MaintainableItem) => ({
  ...equipment,
  attributes: equipment.attributes?.map(attr => ({
    ...attr,
    displayValue:
      attr.type === 'enum'
        ? enumLabels?.[`${equipment.type}_${attr.id}_${attr.value}`] ||
          attr.value
        : attr.value,
  })),
});
