import React, { ReactNode, useEffect, useState } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  BackArrowIcon,
  SideArrowIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import t from 'locales/translation';
import { matchMedia, mediaQuery, mediaQueryMax } from 'utils/style-utils';
import { H2 } from '../Typography';

type Tab = {
  id: string;
  label: ReactNode;
  content: ReactNode;
};

type Props = {
  tabs: Tab[];
};

function Tabs({ tabs }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const route = useRouteMatch();
  const isDesktop = matchMedia.phoneLarge.matches;

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    if (show) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
  }, [show, isDesktop]);

  return (
    <>
      <TabsWrapper>
        {tabs.map(tab => (
          <StyledTab
            key={`Tab_${tab.id}`}
            to={`${tab.id}`}
            onClick={() => setShow(true)}
          >
            {tab.label}
            <StyledIcon as={SideArrowIcon} />
          </StyledTab>
        ))}
      </TabsWrapper>
      <MobileOverlay aria-expanded={show}>
        <BackButtonWrapper>
          <BackButton onClick={() => setShow(false)}>
            <Icon as={BackArrowIcon} />
            {t('Back')}
          </BackButton>
        </BackButtonWrapper>
        <Switch>
          {tabs.map(tab => (
            <Route key={`TabRoute_${tab.id}`} path={`${route.path}/${tab.id}`}>
              <Title>{tab.label}</Title>
              {tab.content}
            </Route>
          ))}
          <Route>
            <Redirect to={`${route.url}/${tabs[0].id}`} />
          </Route>
        </Switch>
      </MobileOverlay>
    </>
  );
}

export default Tabs;

export const TabsWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.white};
  padding-top: ${({ theme }) => theme.spacing.sp12};

  ${mediaQueryMax.phoneLarge} {
    flex-direction: column;
    padding-top: 0;
  }
`;

export const StyledTab = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 34px;
  padding: ${({ theme }) => `0 ${theme.spacing.sp16}`};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.default.blue2};
  border-bottom: 2px solid ${({ theme }) => theme.colors.default.white};
  transition: all 300ms;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.default.blue1};
    border-color: ${({ theme }) => theme.colors.default.blue1};
  }

  ${mediaQueryMax.phoneLarge} {
    height: 36px;
    padding: 0;
    margin: 0 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray1};
    justify-content: space-between;
    text-transform: none;

    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.default.blue2};
      border-color: ${({ theme }) => theme.colors.default.gray1};
    }
  }

  svg {
    width: ${({ theme }) => theme.sizes.sz20};
    height: ${({ theme }) => theme.sizes.sz20};
  }
`;

export const MobileOverlay = styled.div`
  ${mediaQueryMax.phoneLarge} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100vw;
    transition: left 300ms ease-out;
    z-index: 1200;
    background-color: ${({ theme }) => theme.colors.default.gray1};
    overflow-x: hidden;
    overflow-y: scroll;

    &[aria-expanded='true'] {
      left: 0;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.default.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray1};
  display: flex;
  align-items: center;
  z-index: 1201;

  ${mediaQuery.phoneLarge} {
    display: none;
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sp8};
  color: ${({ theme }) => theme.colors.default.blue1};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  cursor: pointer;

  svg {
    width: ${({ theme }) => theme.sizes.sz20};
    height: ${({ theme }) => theme.sizes.sz20};
  }
`;

const Title = styled(H2)`
  margin: 12px 12px 0 12px;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  color: ${({ theme }) => theme.colors.default.blue1};

  ${mediaQuery.phoneLarge} {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  ${mediaQuery.phoneLarge} {
    display: none;
  }
`;
