import {
  put,
  takeLatest,
  all,
  fork,
  actionChannel,
  take,
  delay,
  flush,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { translationString } from 'locales/translation';
import { getHierarchyTreeWithSeverity } from 'services/conditionMonitoringService';
import apiCall from 'services/utils/apiCall';
import { notifyAxiosError, parseAxiosError } from 'utils/error-helpers';
import {
  getMaintainableItemsForLocation,
  getHierarchyTree,
  getSpaceDetails as getSpaceDetailsAPI,
  getAssetDetails as getAssetDetailsAPI,
  getSubUnitDetails as getSubUnitDetailsAPI,
  deleteSpace as deleteSpaceAPI,
  deleteAsset as deleteAssetAPI,
  deleteSubUnit as deleteSubUnitAPI,
  deleteMaintainableItem as deleteMaintainableItemAPI,
  putSpaceDetails as putSpaceDetailsAPI,
  putAssetDetails as putAssetDetailsAPI,
  putUpdateSubUnit as putUpdateSubUnitAPI,
  getMaintainableItemDetails,
  putUpdateMaintainableItem,
  reorderHierarchy as reorderHierarchyAPI,
} from 'services/hierarchyServices';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';

import {
  EntityType,
  HierarchyNode,
  Space,
  SpaceUpdateObject,
  Asset,
  UpdateAssetObject,
  UpdateSubUnitObject,
  SubUnit,
  MaintainableItem,
  UpdateMaintainableItemObject,
} from 'types';
import { actions } from './slice';

const DATA_REFRESH_INTERVAL = 20000;

export function* getCustomerHierarchy(action: PayloadAction<string>) {
  const customerId: string = action.payload;
  try {
    const { data: hierarchy }: { data: HierarchyNode } = yield apiCall(
      getHierarchyTree,
      customerId,
      null,
      EntityType.MAINTAINABLE_ITEM,
    );
    yield put(actions.getCustomerHierarchySuccess(hierarchy));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.CustomerHierarchyFetching'),
    );
    yield put(actions.getCustomerHierarchyFailure(effectError));
  }
}

export function* getHierarchy(
  action: PayloadAction<{ locationId: any; noRefresh?: boolean }>,
) {
  if (!action.payload || action.payload.noRefresh) {
    return;
  }
  try {
    const { locationId } = action.payload;
    yield put(actions.setLoading());
    const [{ data: hierarchy }, { data: maintainableItems }] = yield all([
      apiCall(getHierarchyTreeWithSeverity, locationId),
      apiCall(getMaintainableItemsForLocation, locationId),
    ]);
    yield put(actions.setMaintainableItems(maintainableItems));
    yield put(actions.getHierarchySuccess(hierarchy));
  } catch (error) {
    const effectError = yield parseAxiosError(
      error,
      translationString('ErrorMessage.CustomerHierarchyFetching'),
    );
    yield put(actions.getHierarchyFailure(effectError));
  }
}

export function* reorderHierarchy(
  action: PayloadAction<{
    customerId: string;
    parentId: string;
    childIds: string[];
  }>,
) {
  const { customerId, parentId, childIds } = action.payload;

  try {
    yield apiCall(reorderHierarchyAPI, parentId, childIds);

    const { data: hierarchy }: { data: HierarchyNode } = yield apiCall(
      getHierarchyTree,
      customerId,
      null,
      EntityType.MAINTAINABLE_ITEM,
    );

    yield put(actions.getCustomerHierarchySuccess(hierarchy));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.CustomerHierarchyOrder'),
    );
    yield put(actions.getHierarchyFailure(effectError));
  }
}

export function* deleteSpace(
  action: PayloadAction<{
    spaceId: string;
    customerId: string;
  }>,
) {
  const spaceId: string = action.payload.spaceId;
  const customerId: string = action.payload.customerId;
  try {
    yield apiCall(deleteSpaceAPI, spaceId);
    yield put(actions.getCustomerHierarchy(customerId));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.EntityDeleted.Space'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DeleteEntity.Space'),
    );
  }
}

export function* deleteAsset(
  action: PayloadAction<{
    assetId: string;
    customerId: string;
  }>,
) {
  const assetId: string = action.payload.assetId;
  const customerId: string = action.payload.customerId;
  try {
    yield apiCall(deleteAssetAPI, assetId);
    yield put(actions.getCustomerHierarchy(customerId));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.EntityDeleted.Asset'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DeleteEntity.Asset'),
    );
  }
}

export function* deleteSubUnit(
  action: PayloadAction<{
    subUnitId: string;
    customerId: string;
  }>,
) {
  const subUnitId: string = action.payload.subUnitId;
  const customerId: string = action.payload.customerId;
  try {
    yield apiCall(deleteSubUnitAPI, subUnitId);
    yield put(actions.getCustomerHierarchy(customerId));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.EntityDeleted.Subunit'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DeleteEntity.Subunit'),
    );
  }
}

export function* deleteMaintainableItem(
  action: PayloadAction<{
    maintainableItemId: string;
    customerId: string;
  }>,
) {
  const maintainableItemId: string = action.payload.maintainableItemId;
  const customerId: string = action.payload.customerId;
  try {
    yield apiCall(deleteMaintainableItemAPI, maintainableItemId);
    yield put(actions.getCustomerHierarchy(customerId));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.EntityDeleted.MaintainableItem'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.DeleteEntity.MaintainableItem'),
    );
  }
}

export function* reparentSpace(
  action: PayloadAction<{
    spaceId: string;
    newParentId: string;
    customerId: string;
  }>,
) {
  try {
    const { data: spaceData }: { data: Space } = yield apiCall(
      getSpaceDetailsAPI,
      action.payload.spaceId,
    );

    const updateObject: SpaceUpdateObject = {
      label: spaceData.label.id,
      name: spaceData.name,
      parentId: action.payload.newParentId,
    };

    yield apiCall(putSpaceDetailsAPI, action.payload.spaceId, updateObject);
    yield put(actions.getCustomerHierarchy(action.payload.customerId));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.Space'),
    );
  }
}

export function* reparentAsset(
  action: PayloadAction<{
    assetId: string;
    newParentId: string;
    customerId: string;
  }>,
) {
  try {
    const { data: assetData }: { data: Asset } = yield apiCall(
      getAssetDetailsAPI,
      action.payload.assetId,
    );

    const updateObject: UpdateAssetObject = {
      atexZone: assetData.atexZone,
      criticality: assetData.criticality || '',
      description: assetData.description,
      name: assetData.name,
      positionNumber: assetData.positionNumber,
      spaceId: action.payload.newParentId,
    };

    yield apiCall(putAssetDetailsAPI, action.payload.assetId, updateObject);
    yield put(actions.getCustomerHierarchy(action.payload.customerId));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.Asset'),
    );
  }
}

export function* reparentSubUnit(
  action: PayloadAction<{
    subUnitId: string;
    newParentId: string;
    customerId: string;
  }>,
) {
  try {
    const { data: subUnitData }: { data: SubUnit } = yield apiCall(
      getSubUnitDetailsAPI,
      action.payload.subUnitId,
      true,
    );

    const updateObject: UpdateSubUnitObject = {
      assetId: action.payload.newParentId,
      attributes: subUnitData.attributes || [],
      type: subUnitData.type,
    };

    yield apiCall(putUpdateSubUnitAPI, action.payload.subUnitId, updateObject);
    yield put(actions.getCustomerHierarchy(action.payload.customerId));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.Subunit'),
    );
  }
}

export function* reparentMaintainableItem(
  action: PayloadAction<{
    maintainableItemId: string;
    newParentId: string;
    customerId: string;
  }>,
) {
  try {
    const { data: itemData }: { data: MaintainableItem } = yield apiCall(
      getMaintainableItemDetails,
      action.payload.maintainableItemId,
    );

    const updateObject: UpdateMaintainableItemObject = {
      attributes: itemData.attributes
        ? itemData.attributes.map(itm => ({
            id: itm.id,
            value: itm.value,
          }))
        : [],
      subUnitId: action.payload.newParentId,
      type: itemData.type,
    };
    yield apiCall(
      putUpdateMaintainableItem,
      action.payload.maintainableItemId,
      updateObject,
    );
    yield put(actions.getCustomerHierarchy(action.payload.customerId));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.MaintainableItem'),
    );
  }
}

export function* assetsNavigatorSaga() {
  const throttleChannel = yield actionChannel(
    actions.refreshHierarchy.type,
    buffers.sliding(1),
  );
  yield takeLatest(actions.getCustomerHierarchy.type, getCustomerHierarchy);
  yield takeLatest(actions.getHierarchy.type, getHierarchy);
  yield takeLatest(actions.reorderHierarchy.type, reorderHierarchy);
  yield takeLatest(actions.deleteSpace.type, deleteSpace);
  yield takeLatest(actions.deleteAsset.type, deleteAsset);
  yield takeLatest(actions.deleteSubUnit.type, deleteSubUnit);
  yield takeLatest(actions.deleteMaintainableItem.type, deleteMaintainableItem);
  yield takeLatest(actions.reparentSpace.type, reparentSpace);
  yield takeLatest(actions.reparentAsset.type, reparentAsset);
  yield takeLatest(actions.reparentSubUnit.type, reparentSubUnit);
  yield takeLatest(
    actions.reparentMaintainableItem.type,
    reparentMaintainableItem,
  );
  yield throttle(DATA_REFRESH_INTERVAL, throttleChannel, getHierarchy);
  yield bypassThrottle(
    actions.resetHierarchy.type,
    getHierarchy,
    throttleChannel,
  );
}

const bypassThrottle = (pattern: string, task, throttleChannel, ...args) =>
  fork(function* () {
    while (true) {
      yield flush(throttleChannel);
      const action = yield take(pattern);
      // use no refresh so only the throttling is done
      yield put(
        actions.refreshHierarchy({
          locationId: action.payload,
          noRefresh: true,
        }),
      );
      // bypass throttling by firing getHierarchy directly
      yield put(actions.getHierarchy(action.payload));
    }
  });

const throttle = (ms, throttleChannel, task, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(throttleChannel);
      yield fork(task, ...args, action);
      yield delay(ms);
    }
  });
