/**
 *
 * TaskForm
 *
 */
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import {
  Button,
  GridRow,
  GridColumn,
  Checkbox,
} from '@eriksdigital/atomic-ui/components';

import t, { translationString } from 'locales/translation';
import { formatDateTime } from 'locales/date-format-i18n';
import {
  AlertType,
  ValueLabel,
  TaskReq,
  Alert,
  EntityType,
  TaskStatus,
  Task,
  TaskItem,
  TextColor,
  Asset,
} from 'types';
import { useUserList } from 'utils/hooks/useUserList';
import { fetchAlerts } from 'utils/alerts';
import { getConditionOrStatus } from 'utils/getConditionOrStatus';
import { getValue } from 'utils/getDiagnosticAdviceValue';
import { useUserDetails } from 'app/providers/AuthProvider';
import { Label } from 'app/elements/Fields';
import { Div } from 'app/components/Div';
import { Controls } from 'app/components/Controls';
import { Divider } from 'app/components/Divider';
import { H3, Text } from 'app/components/Typography';
import { FieldWrapper } from 'app/components/EntityForms/FieldWrapper';
import {
  FinalDatePicker,
  FinalTextArea,
  FinalTextField,
} from 'app/components/FinalField';
import CustomSelect from 'app/components/Select/CustomSelect';
import { getParentTree } from 'services/hierarchyServices';

interface Props {
  task?: Task;
  item: TaskItem;
  relatedAlertId?: string;
  relatedItems?: ValueLabel[];
  onSubmit: Function;
  onCancel: Function;
}

const getLabel = (alert: Alert): string => {
  const type =
    alert.type === AlertType.STATUS
      ? translationString('Status')
      : translationString('ConditionScore');
  const value =
    alert.type === AlertType.STATUS ? alert.value : alert.conditionScore;
  const time = formatDateTime(alert.startTime);

  return `${alert.name} | ${type}: ${value} | ${time}`;
};

export function TaskEditor({
  item,
  task,
  relatedAlertId,
  relatedItems,
  onSubmit,
  onCancel,
}: Props) {
  const user = useUserDetails();
  const userList: ValueLabel[] = useUserList();
  const { locationId } = useParams() as { locationId: string };

  const [asset, setAsset] = useState<Asset>();
  const [alert, setAlert] = useState<Alert | undefined>();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [status, setStatus] = useState<ValueLabel>();
  const [assignee, setAssignee] = useState<ValueLabel>();
  const [relatedItem, setRelatedItem] = useState<ValueLabel>();

  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [showCloseDate, setShowCloseDate] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState(task?.comment || undefined);
  const [closeDate, setCloseDate] = useState<Date | undefined>();
  const [createdDate, setCreatedDate] = useState<Date>(
    task?.createdDate ? new Date(task.createdDate) : new Date(),
  );
  const [keepMeNotified, setKeepNotified] = useState<boolean>(
    task?.notifyMe || false,
  );

  // let alerts: Alert[] = useAlerts(item.id, item.type, asset?.id);

  const statusList: ValueLabel[] = useMemo(() => {
    if (task) {
      setStatus({ label: translationString(task.status), value: task.status });

      return [
        { label: translationString(TaskStatus.TODO), value: TaskStatus.TODO },
        { label: translationString(TaskStatus.DONE), value: TaskStatus.DONE },
      ];
    }

    setStatus({
      label: translationString(TaskStatus.DRAFT),
      value: TaskStatus.DRAFT,
    });

    return [
      { label: translationString(TaskStatus.DRAFT), value: TaskStatus.DRAFT },
      { label: translationString(TaskStatus.TODO), value: TaskStatus.TODO },
    ];
  }, [task]);

  // Get related Asset
  useEffect(() => {
    getParentTree(item.id, 2, [EntityType.ASSET]).then(({ data }) => {
      if (data.parent) setAsset(data.parent);
    });
  }, [item.id]);

  // Get related Alerts
  useEffect(() => {
    fetchAlerts(item.id, item.type, asset?.id).then(data => {
      setAlerts(data);
    });
  }, [asset, item.id, item.type]);

  useEffect(() => {
    if (!relatedItems) return;

    setRelatedItem(relatedItems.find(rel => rel.value === item.id));
  }, [relatedItems, item.id]);

  useEffect(() => {
    if (!task) return;

    const item = userList.find(user => user.value === task.assignee?.id);
    if (item) setAssignee(item);
  }, [task, userList]);

  useEffect(() => {
    if (!relatedAlertId) return;

    const alert = alerts.find(alert => alert.id === relatedAlertId);
    if (alert) setAlert(alert);
  }, [alerts, relatedAlertId]);

  const handleItemSelection = (option: ValueLabel) => {
    setRelatedItem(option);
    setAlert(undefined);

    fetchAlerts(option.value, item.type, asset?.id).then(data => {
      setAlerts(data);
    });
  };

  const handleAlertSelection = (option: ValueLabel) => {
    const alert = alerts.find(a => a.id === option.value);
    setAlert(alert);
  };

  const handleSubmit = () => {
    const updatedTask: TaskReq = {
      id: task?.id,
      locationId,
      assetId: asset?.id,
      createdBy: user?.id,
      isActive: task?.isActive,
      title: title?.trim(),
      description: description?.trim(),
      createdDate: createdDate.toISOString(),
      closeDate: closeDate?.toISOString(),
      relatedAlertId: alert?.id,
      status: status?.value as TaskStatus,
      assignee: assignee?.value,
      comment: comment?.trim(),
      notifyMe: keepMeNotified || false,
    };

    if (item.type === EntityType.SUB_UNIT) {
      updatedTask.subUnitId = relatedItem?.value || item.id;
    } else if (item.type === EntityType.MAINTAINABLE_ITEM) {
      updatedTask.maintainableItemId = relatedItem?.value || item.id;
    }

    onSubmit(updatedTask, relatedItem?.value);
  };

  return (
    <form>
      <GridRow>
        <GridColumn desktop={6}>
          <SubTitle>{t('Generalinformation')}</SubTitle>

          <Div display="block" padding="0 0 " mb="36px" mr="20px">
            {asset && (
              <>
                <Div
                  display="flex"
                  justifyContent="space-between"
                  pt="10px"
                  pb="10px"
                >
                  <Label>{t('TableColumnHeaders.AssetName')}</Label>
                  <Text fontSize="ft14">{asset?.name}</Text>
                </Div>

                <Divider />

                <Div
                  display="flex"
                  justifyContent="space-between"
                  pt="10px"
                  pb="10px"
                >
                  <Label>{t('PositionNumber')}</Label>
                  <Text fontSize="ft14">{asset?.positionNumber}</Text>
                </Div>

                <Divider />
              </>
            )}

            <Div
              display="flex"
              justifyContent="space-between"
              pt="10px"
              pb="10px"
            >
              <Label>{t(item.type)}</Label>
              {!relatedItems && <Text fontSize="ft14">{item.name}</Text>}
            </Div>

            {relatedItems && (
              <CustomSelect
                selectedItem={relatedItem}
                options={relatedItems}
                handleSelection={handleItemSelection}
              />
            )}
          </Div>

          {alerts.length > 0 && (
            <>
              <SubTitle>{t('AlertInformation')}</SubTitle>

              <Div display="block" mb="24px" mr="20px">
                <FieldWrapper>
                  <Label>{t('SelectCorrespondingAlert')}</Label>
                  <CustomSelect
                    selectedItem={
                      alert && { value: alert.id, label: getLabel(alert) }
                    }
                    options={alerts.map(alert => ({
                      value: alert.id,
                      label: getLabel(alert),
                    }))}
                    handleSelection={handleAlertSelection}
                  />
                </FieldWrapper>

                {alert && (
                  <>
                    <Div
                      display="flex"
                      justifyContent="space-between"
                      pt="10px"
                      pb="10px"
                    >
                      <Label>{t('Sensor')}</Label>
                      <Text fontSize="ft14">{alert.name}</Text>
                    </Div>

                    <Divider />

                    <Div
                      display="flex"
                      justifyContent="space-between"
                      pt="10px"
                      pb="10px"
                    >
                      <Label>
                        {alert.type === AlertType.STATUS
                          ? t('Status')
                          : t('ConditionScore')}
                      </Label>
                      <Text fontSize="ft14">{getConditionOrStatus(alert)}</Text>
                    </Div>

                    <Divider />

                    <Div
                      display="flex"
                      justifyContent="space-between"
                      pt="10px"
                      pb="10px"
                    >
                      <Label>{t('TriggeredOn')}</Label>
                      <Text fontSize="ft14">
                        {formatDateTime(alert.startTime)}
                      </Text>
                    </Div>

                    <Divider />

                    <Div justifyContent="start" pt="10px" pb="10px">
                      <Label>{t('Diagnostic')}</Label>
                      <br />
                      <Text fontSize="ft14">
                        {getValue(
                          'Diagnostic',
                          alert.diagnostic,
                          alert.diagnosticCode,
                        )}
                      </Text>
                    </Div>

                    <Divider />

                    <Div justifyContent="start" pt="10px" pb="10px">
                      <Label>{t('Advice')}</Label>
                      <br />
                      <Text fontSize="ft14">
                        {getValue('Advice', alert.advice, alert.adviceCode)}
                      </Text>
                    </Div>
                  </>
                )}
              </Div>
            </>
          )}

          {task && (
            <>
              {task.createdDate && (
                <Div display="flex" mb="8px" data-stringid="CreatedAt">
                  <Text color={TextColor.muted}>
                    {`${translationString('CreatedAt')} ${formatDateTime(
                      task.createdDate,
                    )}`}
                  </Text>
                </Div>
              )}

              {task.closeDate && (
                <Div display="flex" mb="8px" data-stringid="ResolvedAt">
                  <Text color={TextColor.muted}>
                    {`${translationString('ResolvedAt')} ${formatDateTime(
                      task.closeDate,
                    )}`}
                  </Text>
                </Div>
              )}
            </>
          )}
        </GridColumn>

        <Column desktop={6}>
          <H3>{task ? t('EditTask') : t('CreateTask')}</H3>

          <SelectContainer>
            <CustomSelect
              width="100px"
              selectedItem={status}
              options={statusList}
              handleSelection={option => {
                if (status?.value === option.value) return;

                setStatus(option);

                if (
                  task?.status !== TaskStatus.DONE &&
                  option.value === TaskStatus.DONE
                ) {
                  setShowCloseDate(true);
                  setShowComment(true);
                  setCloseDate(new Date());
                } else if (
                  task?.status === TaskStatus.DONE &&
                  option.value === TaskStatus.TODO
                ) {
                  setShowCloseDate(false);
                  setShowComment(true);
                  setCloseDate(undefined);
                } else {
                  setShowCloseDate(false);
                  setShowComment(false);
                  setCloseDate(undefined);
                  setComment('');
                }
              }}
            />

            <CustomSelect
              width="200px"
              isSearchable
              selectedItem={assignee || userList[0]}
              options={userList}
              handleSelection={option => setAssignee(option)}
            />
          </SelectContainer>

          <FieldWrapper required>
            <FinalTextField
              id="title"
              data-testid="title"
              label={t('Title')}
              placeholder={translationString('Title')}
              input={{
                id: 'title',
                name: 'title',
                value: title,
                onChange: e => setTitle(e.target.value),
              }}
            />
          </FieldWrapper>

          <FieldWrapper required>
            <FinalTextArea
              id="description"
              data-testid="description"
              label={t('Attributes.description')}
              placeholder={translationString('Description')}
              disabledResize={true}
              rows="6"
              maxLength="1024"
              input={{
                id: 'description',
                name: 'description',
                value: description,
                onChange: e => setDescription(e.target.value),
              }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <FinalDatePicker
              data-testid="createdDate"
              label={t('Attributes.creationDate')}
              showTimeSelect
              input={{
                id: 'createdDate',
                name: 'createdDate',
                value: createdDate,
                onChange: (date: Date) => setCreatedDate(date),
              }}
            />
          </FieldWrapper>

          {showCloseDate && (
            <FieldWrapper>
              <FinalDatePicker
                data-testid="closeDate"
                label={t('Attributes.closeDate')}
                showTimeSelect
                minDate={createdDate}
                input={{
                  id: 'closeDate',
                  name: 'closeDate',
                  value: closeDate,
                  onChange: (date: Date) => setCloseDate(date),
                }}
              />
            </FieldWrapper>
          )}

          {showComment && (
            <FieldWrapper required>
              <FinalTextArea
                id="comment"
                data-testid="comment"
                label={t('Comment')}
                placeholder={translationString('Comment')}
                disabledResize={true}
                rows="6"
                maxLength="1024"
                input={{
                  id: 'comment',
                  name: 'comment',
                  value: comment,
                  onChange: e => setComment(e.target.value),
                }}
              />
            </FieldWrapper>
          )}

          <Div
            mt="24px"
            display="flex"
            align="center"
            justifyContent="space-between"
          >
            <Checkbox
              small
              name="notifyMe"
              checked={keepMeNotified}
              onChange={() => setKeepNotified(!keepMeNotified)}
            >
              {translationString('KeepMeNotified')}
            </Checkbox>

            <Controls>
              <Button
                id="cancel_button"
                type="button"
                variant="secondary"
                stretch="auto"
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                {t('Cancel')}
              </Button>

              <Button
                id="save_button"
                stretch="auto"
                disabled={!title || !description || (showComment && !comment)}
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {t('SubmitTask')}
              </Button>
            </Controls>
          </Div>
        </Column>
      </GridRow>
    </form>
  );
}

const Column = styled(GridColumn)`
  padding-left: 20px;
`;

const SubTitle = styled(Div)`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-bottom: ${({ theme }) => theme.spacing.sp12};
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  div:first-child {
    margin-right: 8px;
  }
`;
