import { ResourceType } from 'types';

export const CUTOFF_CRITICAL = 10; // 9-10 is critical
export const CUTOFF_WARNING = 9; // 6-7-8 is warning
export const CUTOFF_GOOD = 6; //  1-2-3-4-5 good
export const CUTOFF_UNKNOWN = 1; // lower than 1 is unknown
export const REMEMBER_REQUEST_PATH_KEY = 'SAM_REMEMBER_PATH';
export const REMEMBER_LOCATION_KEY = 'samLastLocation';
export const REMEMBER_CUSTOMER_KEY = 'samLastCustomer';
export const ANNOUNCE_NEW_LAYOUT = 'announceNewLayout';
export const LANGUAGE_CODE = 'languageCode';
export const USER_LANGUAGE_CODE = 'userLanguageCode';
export const ROUTES = {
  Dashboard: '/c/:customerId/dashboard/:locationId?',
  Space: '/c/:customerId/dashboard/:locationId/space/:spaceId',
  Asset: '/c/:customerId/dashboard/:locationId/asset/:assetId',
  Equipments: '/c/:customerId/dashboard/:locationId/equipments/:miTypeId',
  EquipmentDetail:
    '/c/:customerId/dashboard/:locationId/equipments/:miTypeId/details/:miId',
  EquipmentForm:
    '/c/:customerId/dashboard/:locationId/equipments/:miTypeId/:action/:miId?',
};

export const MEDIA_RESOURCE_TYPES = [
  ResourceType.IMAGE,
  ResourceType.LOGO,
  ResourceType.NAME_PLATE_PHOTO,
];

export const ATTACHMENTS_RESOURCE_TYPES = [
  ResourceType.ATTACHMENT,
  ResourceType.LUBRICATION_REPORT,
  ResourceType.OTHER,
  ResourceType.QUOTE,
  ResourceType.REPAIR_REPORT,
  ResourceType.TERMO_REPORT,
  ResourceType.THERMOGRAPHY_BASELINE,
  ResourceType.VIBRATION_REPORT,
  ResourceType.VIDEO,
];

export const DOCUMENTS_RESOURCE_TYPES = [
  ResourceType.ATTACHMENT,
  ResourceType.CERTIFICATE,
  ResourceType.IMAGE,
  ResourceType.LUBRICATION_REPORT,
  ResourceType.NAME_PLATE_PHOTO,
  ResourceType.OTHER,
  ResourceType.QUOTE,
  ResourceType.REPAIR_REPORT,
  ResourceType.TERMO_REPORT,
  ResourceType.THERMOGRAPHY_BASELINE,
  ResourceType.VIBRATION_REPORT,
  ResourceType.VIDEO,
];
