import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

type PropType<T> = {
  fetchPromise: () => Promise<AxiosResponse<T>>;
  lazy?: boolean;
};

export function useFetch<T>({ fetchPromise, lazy = false }: PropType<T>) {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const doFetch = useCallback(() => {
    setIsLoading(true);
    fetchPromise()
      .then(response => {
        isMounted && setData(response.data);
      })
      .catch(err => {
        isMounted && setError(err);
      })
      .finally(() => {
        isMounted && setIsLoading(false);
      });
  }, [fetchPromise, setData, setError, isMounted]);

  useEffect(() => {
    !lazy && doFetch();

    return () => {
      setIsMounted(false);
    };
  }, [doFetch, lazy]);

  return {
    isLoading,
    data,
    error,
    doFetch,
  };
}
