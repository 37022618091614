import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { RootState } from 'types';
import { initialState } from './slice';
import {
  getVisibleFilters,
  hydrateEquipments,
  reduceEnumLabels,
} from './utils';
import { EquipmentFilters } from './types';

const selectDomain = (state: RootState) => state.equipments || initialState;

export const selectMetadataDomain = createSelector(
  [selectDomain],
  equipmentsState => equipmentsState.metadata,
);

export const selectMetadata = createSelector(
  [selectMetadataDomain],
  metadata => metadata.data,
);

export const selectEnumLabels = createSelector([selectMetadata], metadata =>
  metadata ? reduceEnumLabels(metadata) : {},
);

export const selectIsLoadingMetadata = createSelector(
  [selectMetadataDomain],
  metadata => metadata.loading,
);

export const selectAttributeGroups = createSelector(
  [selectMetadata],
  metadata => metadata?.attributeGroups,
);

export const selectAllEquipmentTypes = createSelector(
  [selectMetadata],
  metadata => (metadata ? sortBy(metadata.maintainableItemTypes, 'label') : []),
);

export const selectEquipmentType = (miTypeId: string) =>
  createSelector([selectMetadata], metadata =>
    metadata?.maintainableItemTypes.find(
      et => et.maintainableItemTypeId === miTypeId,
    ),
  );

export const selectEquipmentTypesDomain = createSelector(
  [selectDomain],
  equipmentsState => equipmentsState.equipmentTypes,
);

export const selectEquipmentTypes = createSelector(
  [selectEquipmentTypesDomain],
  equipmentTypes => equipmentTypes.data,
);

export const selectIsLoadingEquipmentTypes = createSelector(
  [selectEquipmentTypesDomain],
  equipmentTypes => equipmentTypes.loading,
);

export const selectEquipmentsDomain = createSelector(
  [selectDomain],
  equipmentsState => equipmentsState.equipments,
);

export const selectEquipmentsData = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.data,
);

export const selectEquipments = createSelector(
  [selectEquipmentsData, selectEnumLabels],
  (equipments, enumLabels) =>
    equipments ? hydrateEquipments(equipments, enumLabels) : [],
);

export const selectEquipmentsAllMap = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.allMap,
);

export const selectIsLoadingEquipments = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.loading,
);

export const selectPageInfo = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.pageInfo,
);

export const selectSort = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.sort,
);

export const selectFilters = createSelector(
  [selectEquipmentsDomain],
  equipments => equipments.filters,
);

export const selectEquipmentsPayload = createSelector(
  [selectDomain],
  equipmentsState => {
    const { miTypeId, locationId, equipments } = equipmentsState;
    const { pageInfo, sort, searchText, filters } = equipments;
    let visibleFilters: EquipmentFilters = {};
    if (filters && miTypeId) {
      visibleFilters = getVisibleFilters(filters, miTypeId);
    }
    const fields = visibleFilters
      ? Object.entries(visibleFilters)
          .filter(([fieldName, { values: searchTerm }]) => searchTerm.length)
          .map(([fieldName, { type: fieldType, values: searchTerm }]) => ({
            fieldName,
            fieldType,
            searchTerm,
          }))
      : [];
    const search = { mainTerm: searchText, fields };

    return {
      miTypeId,
      locationId,
      pageIndex: pageInfo?.page,
      pageSize: pageInfo?.pageSize,
      sort: sort
        ? { field: sort.columnId, order: sort.sortDirection }
        : undefined,
      search,
    };
  },
);

export const selectHierarchyTree = createSelector(
  [selectDomain],
  equipmentsState => equipmentsState.hierarchyTree,
);
