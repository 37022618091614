import React from 'react';
import { Div } from 'app/components/Div';
import { Attribute, BgColor, TextColor } from 'types';
import { GridColumn } from '@eriksdigital/atomic-ui/components';
import styled from 'styled-components/macro';
import { capitalizeFirstLetter } from 'utils/formatters';
import { getGroupedAttributes, getOrderedAttributeGroupNames } from '../utils';
import { GroupedAttributes } from '../types';
import { Text } from 'app/components/Typography';
import t, { translateAttributeLabel } from 'locales/translation';
import { ButtonSecondary } from 'app/elements/Buttons';

interface SingleGroupColumnProps {
  attributes: Attribute[];
}

interface Props extends SingleGroupColumnProps {
  openDetails: Function;
}

export const AttributesTable = ({ attributes, openDetails }: Props) => {
  const attributeGroups = getOrderedAttributeGroupNames(attributes);
  let groupedAttributes: GroupedAttributes[] = getGroupedAttributes(
    attributes,
    attributeGroups,
  );

  return (
    <>
      <Div display="flex" width="100%">
        {groupedAttributes.length > 1 &&
          groupedAttributes
            .slice(0, 2)
            .map((groupItem: GroupedAttributes, index: number) => (
              <StyledGridColumn key={index} desktop={6} mobile={12}>
                {DoubleGroupColumn(groupItem)}
              </StyledGridColumn>
            ))}
        {groupedAttributes.length === 1 && (
          <Div display="flex" width="100%" direction="column">
            <Div
              display="flex"
              color={TextColor.primaryNew}
              height="46px"
              width="100%"
              bg={BgColor.white}
              align="center"
              padding="16px"
            >
              <Text fontSize="ft14" fontWeight="medium">
                {capitalizeFirstLetter(groupedAttributes[0].groupName)}
              </Text>
            </Div>
            <Div display="flex" width="100%">
              <SingleGroupColumn
                attributes={groupedAttributes[0].attributes.slice(0, 7)}
              />
              <SingleGroupColumn
                attributes={groupedAttributes[0].attributes.slice(8, 15)}
              />
            </Div>
          </Div>
        )}
      </Div>
      <Div
        color={TextColor.primaryNew}
        height="46px"
        width="100%"
        bg={BgColor.white}
        align="center"
        display="flex"
        justifyContent="flex-end"
        padding="16px"
      >
        <StyledButtonSecondary onClick={() => openDetails(true)}>
          {t('All_Specifications')}
        </StyledButtonSecondary>
      </Div>
    </>
  );
};

const DoubleGroupColumn = (groupItem: GroupedAttributes) => {
  return (
    <>
      <Div
        color={TextColor.primaryNew}
        height="46px"
        bg={BgColor.white}
        align="center"
        display="flex"
        padding="16px"
      >
        <Text fontSize="ft14" fontWeight="medium">
          {capitalizeFirstLetter(groupItem.groupName)}
        </Text>
      </Div>
      <SingleGroupColumn attributes={groupItem.attributes.slice(0, 7)} />
    </>
  );
};

const SingleGroupColumn = ({ attributes }: SingleGroupColumnProps) => {
  return (
    <StyledDiv display="flex" direction="column">
      {attributes.map((attributeItem: Attribute) => (
        <Div
          key={attributeItem.id}
          display="flex"
          justifyContent="space-between"
          lineHeight="14px"
          width="100%"
        >
          <StyledText fontSize="ft14" color={TextColor.tertiary}>
            {translateAttributeLabel(attributeItem.id, attributeItem.label)}
          </StyledText>
          <StyledText fontSize="ft14" color={TextColor.muted}>
            {attributeItem.displayValue !== ''
              ? attributeItem.displayValue
              : '-'}
          </StyledText>
        </Div>
      ))}
    </StyledDiv>
  );
};

const StyledButtonSecondary = styled(ButtonSecondary)`
  :hover {
    text-decoration: underline;
  }
`;

export const StyledDiv = styled(Div)`
  flex-basis: 50%;
  & > div:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.default.gray1};
  }
  & > div:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.default.white};
  }
  & > div {
    padding: 11px 16px;
  }
`;

const StyledGridColumn = styled(GridColumn)`
  margin-bottom: 0;
  padding: 0;
`;

const StyledText = styled(Text)`
  line-height: 14px;
`;
