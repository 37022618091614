import React from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { Icon, Button, H2 } from '@eriksdigital/atomic-ui/components';
import { CrossIcon, CheckIcon } from '@eriksdigital/atomic-ui/components/Icons';

import t, { translationString } from 'locales/translation';
import { notEmpty } from 'utils/validators';
import { Modal } from 'app/components/Modal';
import { FinalDatePicker, FinalTextField } from 'app/components/FinalField';
import { EnrichedResourceFileObject, Resource } from 'types';

interface Props {
  open: boolean;
  resource?: Resource | EnrichedResourceFileObject;
  onCancel: Function;
  onConfirm: Function;
}

export const ResourceEditForm = ({
  open,
  resource,
  onCancel,
  onConfirm,
}: Props) => {
  const handleSubmit = form => {
    onConfirm(resource, {
      description: form.description,
      reportDate: form.reportDate,
    });
  };

  return (
    <>
      {open && (
        <Modal
          onClose={onCancel}
          showCloseIcon={false}
          maxWidth={820}
          id="edit_resource_modal"
          data-testid="edit_resource_modal"
          overflowY="visible"
        >
          <DialogHeader>{t('Resources.Actions.EditResource')}</DialogHeader>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              ...resource,
              reportDate: !!resource ? new Date(resource.reportDate) : null,
            }}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FieldWrapper>
                    <Field
                      name="description"
                      validate={value =>
                        notEmpty(value, {
                          errorMessage: t(
                            'ErrorMessage.FieldValidation.NotEmpty',
                          ),
                        })
                      }
                    >
                      {props => (
                        <FinalTextField
                          label={t('Description')}
                          id="description_input"
                          {...props}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label={t('ReportDate')}
                      name="reportDate"
                      component={FinalDatePicker}
                    />
                  </FieldWrapper>

                  <ActionsWrapper>
                    <Button
                      id="edit_resource_cancel"
                      data-testid="edit_resource_cancel"
                      text={translationString('Cancel')}
                      data-stringid="Cancel"
                      stretch="auto"
                      onClick={e => {
                        e.preventDefault();
                        onCancel();
                      }}
                      leftIcon={<Icon as={CrossIcon} />}
                    />
                    <Button
                      id="edit_resource_confirm"
                      data-testid="edit_resource_confirm"
                      text={translationString('Confirm')}
                      data-stringid="Confirm"
                      stretch="auto"
                      type="submit"
                      leftIcon={<Icon as={CheckIcon} />}
                    />
                  </ActionsWrapper>
                </form>
              );
            }}
          />
        </Modal>
      )}
    </>
  );
};

const DialogHeader = styled(H2)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
  margin: 0 0 ${({ theme }) => theme.spacing.sp12};
`;

const FieldWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sp12} 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: ${({ theme }) => theme.spacing.sp8};
  }
`;
