import React from 'react';
import styled from 'styled-components/macro';

import { formatDate, formatDateTime } from 'locales/date-format-i18n';
import { inputBorder } from 'styles';
import { WrappedComponentProps } from './types';
import { ErrorMessage } from './ErrorMessage';
import { Datepicker } from '../DatePicker';

export const DateFieldAdapter = ({
  input,
  label,
  disabled,
  meta,
  showTimeSelect,
  ...otherProps
}: WrappedComponentProps) => {
  return (
    <>
      <label>{label}</label>

      <Datepicker
        id="date_input"
        selected={input.value}
        onChange={date => input.onChange(date)}
        customInput={
          <CustomInput role="button">
            {input.value && (
              <>
                {showTimeSelect
                  ? formatDateTime(input.value)
                  : formatDate(input.value)}
              </>
            )}
          </CustomInput>
        }
        maxDate={new Date()}
        showTimeSelect={showTimeSelect}
        {...otherProps}
      ></Datepicker>

      {meta && meta.error && meta.touched && (
        <ErrorMessage>{meta.error}</ErrorMessage>
      )}
    </>
  );
};

const CustomInput = styled.div`
  ${inputBorder};
  padding: 10px 16px;
  cursor: pointer;
`;
