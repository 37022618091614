import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from '../slice';
import { equipmentsSaga } from '../saga';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEquipmentTypes,
  selectIsLoadingEquipmentTypes,
} from '../selectors';
import { useCallback } from 'react';

type Props = {
  customerId: string;
  locationId: string;
};

function useEquipmentTypes({ customerId, locationId }: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: equipmentsSaga,
  });

  const dispatch = useDispatch();

  const equipmentTypes = useSelector(selectEquipmentTypes);
  const isLoadingEquipmentTypes = useSelector(selectIsLoadingEquipmentTypes);

  const fetchEquipmentTypes = useCallback(() => {
    dispatch(actions.getEquipmentTypes(locationId));
  }, [dispatch, locationId]);

  return {
    equipmentTypes,
    isLoadingEquipmentTypes,
    fetchEquipmentTypes,
  };
}

export default useEquipmentTypes;
