import { put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actions } from './slice';
import apiCall from 'services/utils/apiCall';
import { notifyAxiosError, parseAxiosError } from 'utils/error-helpers';
import t, { translationString } from 'locales/translation';
import {
  getMaintainableItemsMetadata as getMaintainableItemsMetadataAPI,
  getEquipmentTypes as getEquipmentTypesAPI,
  getEquipments as getEquipmentsAPI,
  getHierarchyTree as getHierarchyTreeAPI,
} from 'services/hierarchyServices';
import { uploadTemplates as uploadTemplatesAPI } from 'services/fileManagementServices';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectEquipmentsPayload, selectMetadata } from './selectors';
import {
  EquipmentsPostPayload,
  EquipmentsPostResponse,
  UploadTemplatesPayload,
} from './types';
import { objectToFormData } from 'utils/form-data';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { EntityType } from 'types';

export function* getMetadata() {
  try {
    const metadata = yield select(selectMetadata);
    if (!metadata) {
      const { data } = yield apiCall(getMaintainableItemsMetadataAPI);
      yield put(actions.getMetadataSuccess(data));
    } else {
      yield put(actions.getMetadataSkipped());
    }
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.MaintainableItemMetadata'),
    );
    yield put(actions.getMetadataError(effectError));
  }
}

export function* getEquipmentTypes(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(getEquipmentTypesAPI, action.payload);
    yield put(actions.getEquipmentTypesSuccess(data));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.MaintainableItemTypes'),
    );
    yield put(actions.getEquipmentTypesError(effectError));
  }
}

export function* getEquipments() {
  try {
    const payload: EquipmentsPostPayload = yield select(
      selectEquipmentsPayload,
    );
    const { data }: AxiosResponse<EquipmentsPostResponse> = yield apiCall(
      getEquipmentsAPI,
      payload,
    );
    yield put(
      actions.getEquipmentsSuccess({
        data: data.content,
        pageCount: data.totalPages,
        totalRecords: data.numberOfElements,
      }),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.MaintainableItemTypes'),
    );
    yield put(actions.getEquipmentsError(effectError));
  }
}

export function* uploadTemplates(
  action: PayloadAction<UploadTemplatesPayload>,
) {
  try {
    const {
      templateType,
      locationId,
      customerId,
      equipmentTypeId,
      file,
    } = action.payload;
    const uploadformData = objectToFormData({
      templateType,
      locationId,
      customerId,
      equipmentTypeId,
      file,
    });
    yield apiCall(uploadTemplatesAPI, uploadformData);
    yield put(
      announcementActions.addSuccess(
        t('SuccessMessage.UploadTemplates', { fileName: file.name }),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UploadTemplates'),
    );
  }
}

export function* getHierarchyTree(action: PayloadAction<string>) {
  const customerId: string = action.payload;

  try {
    const { data } = yield apiCall(
      getHierarchyTreeAPI,
      customerId,
      null,
      EntityType.SUB_UNIT,
    );
    yield put(actions.getHierarchyTreeSuccess(data.children));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.HierarchyFetching'),
    );
    yield put(actions.getHierarchyTreeFail(effectError));
  }
}

export function* equipmentsSaga() {
  yield takeLatest(actions.getMetadata.type, getMetadata);
  yield takeLatest(actions.getEquipmentTypes.type, getEquipmentTypes);
  yield takeLatest(actions.getEquipments.type, getEquipments);
  yield takeLatest(actions.setPageInfo.type, getEquipments);
  yield takeLatest(actions.setSort.type, getEquipments);
  yield takeLatest(actions.setSearch.type, getEquipments);
  yield takeLatest(actions.setFilters.type, getEquipments);
  yield takeLatest(actions.refetchEquipments.type, getEquipments);
  yield takeLatest(actions.uploadTemplates.type, uploadTemplates);
  yield takeLatest(actions.getHierarchyTree.type, getHierarchyTree);
}
