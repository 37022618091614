/**
 *
 * CarouselGallery
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ItemCarousel, Button } from '@eriksdigital/atomic-ui/components';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { UploadImageLink } from 'app/components/UploadImageLink';
import { EntityType, ResourceType, Resource } from 'types';
import { reducer, sliceKey, actions } from './slice';
import { mediaGallerySaga } from './saga';
import {
  selectMediaItemsLoading,
  selectMediaItemsError,
  selectMediaItems,
  selectLightboxMediaItems,
} from './selectors';

import { translationString } from 'locales/translation';
import { Modal } from 'app/components/Modal';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { SignalMessage, Signal } from 'app/components/SignalMessage';
import { MediaManagement } from 'app/containers/MediaManagement';
import { usePrevious } from 'utils/hooks/usePrevious';
import NO_IMAGE_FOUND from 'assets/images/NO_IMAGE_FOUND.jpg';

interface MediaGalleryProps {
  entityType: EntityType;
  entityId: string;
  entityName?: string;
  resourceTypes: ResourceType[];
  className?: string;
  single?: boolean;
  canManage?: boolean;
  hasLightbox?: boolean;
  roundedBorders?: boolean;
}

export const MediaGallery = ({
  entityType,
  entityId,
  entityName = '',
  resourceTypes,
  className = '',
  single = false,
  canManage = false,
  hasLightbox = false,
  roundedBorders = false,
}: MediaGalleryProps) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: mediaGallerySaga });
  let { customerId, locationId } = useParams() as {
    customerId: string;
    locationId: string;
  };

  const { openLightbox } = useLightbox();
  const dispatch = useDispatch();

  const [mangeMediaModalOpen, setManageMediaModalOpen] = useState<boolean>(
    false,
  );

  const isLoading = useSelector(state =>
    selectMediaItemsLoading(state, entityId),
  );
  const error = useSelector(state => selectMediaItemsError(state, entityId));
  const resources: Resource[] = useSelector(state =>
    selectMediaItems(state, entityId),
  );
  const lbElements = useSelector(state =>
    selectLightboxMediaItems(state, entityId),
  );
  const prevEntityId = usePrevious(entityId);

  useEffect(() => {
    if (entityId !== prevEntityId) {
      dispatch(
        actions.getMediaItems({
          entityType,
          entityId,
          resourceTypes,
        }),
      );
    }
  }, [dispatch, entityId, entityType, prevEntityId, resourceTypes]);

  if (error) return <SignalMessage type={Signal.ALERT} message={error} />;

  return (
    <div className={className}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {resources.length > 0 ? (
            <>
              {single || resources.length === 1 ? (
                <SingleImageFrame>
                  <ImageTile
                    src={
                      resources[0]?.specificProperties?.large ||
                      resources[0].url
                    }
                    title={`${resources[0].description}_image`}
                    onClick={() => (hasLightbox ? openLightbox(0) : null)}
                  />
                </SingleImageFrame>
              ) : (
                <StyledItemCarousel
                  responsiveConfig={{
                    0: {
                      items: 1,
                    },
                  }}
                  items={resources.map((resource, idx) => (
                    <ImageTile
                      src={resource?.specificProperties?.large || resource.url}
                      title={`${resource.description}_image`}
                      onClick={() => (hasLightbox ? openLightbox(idx) : null)}
                    />
                  ))}
                  roundedBorders={roundedBorders}
                />
              )}
            </>
          ) : (
            <NoImagePlaceholder>
              <img src={NO_IMAGE_FOUND} alt="img not available" />
            </NoImagePlaceholder>
          )}
          {canManage && (
            <ManageItemsControls>
              {single ? (
                <UploadImageLink
                  data-stringid="ReplaceImage"
                  entityType={entityType}
                  accept="image/x-png,image/gif,image/jpeg"
                  customerId={customerId}
                  locationId={locationId}
                  acceptedTypes={resourceTypes}
                  entityId={entityId}
                  text={translationString('ReplaceImage')}
                  small
                />
              ) : (
                <Button
                  id={`btnManageMedia-${entityId}`}
                  data-testid={`btnManageMedia-${entityId}`}
                  variant="ghost"
                  onClick={() => setManageMediaModalOpen(true)}
                  text={translationString('Gallery.ManageMedia')}
                  data-stringid="Gallery.ManageMedia"
                />
              )}
            </ManageItemsControls>
          )}
          {hasLightbox && lbElements.length > 0 && (
            <SRLWrapper elements={lbElements} />
          )}
        </>
      )}
      {mangeMediaModalOpen && canManage && (
        <Modal
          onClose={() => setManageMediaModalOpen(false)}
          withOutsideClose={true}
        >
          <MediaManagement
            customerId={customerId}
            locationId={locationId}
            resourceTypes={resourceTypes}
            entityType={entityType}
            entityId={entityId}
            entityName={entityName}
          />
        </Modal>
      )}
    </div>
  );
};

const StyledItemCarousel = styled(ItemCarousel)<{ roundedBorders?: boolean }>`
  ${({ theme, roundedBorders }) =>
    roundedBorders
      ? `
    padding: 0 0 ${theme.spacing.sp8};
    .alice-carousel__wrapper {
      border-radius: 10px;
    }
    `
      : `padding: ${theme.spacing.sp8};`}

  #roundExtraLarge {
    width: ${({ theme }) => theme.sizes.sz40};
    height: ${({ theme }) => theme.sizes.sz40};
  }
`;

const NoImagePlaceholder = styled.div<{ roundedBorders?: boolean }>`
  height: 300px;
  background-color: ${({ theme }) => theme.colors.background.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  ${({ roundedBorders }) => roundedBorders && `border-radius: 10px;`}
`;

const SingleImageFrame = styled.div<{ roundedBorders?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  ${({ roundedBorders }) =>
    roundedBorders &&
    `
    border-radius: 10px;
    overflow: hidden;
    `}
`;

const ImageTile = styled.div<{ src: string }>`
  height: 300px;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
`;

const ManageItemsControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing.sp8};
  button {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  }
`;
